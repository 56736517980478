import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { Typography,Tooltip, Button } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {  ThemeProvider } from '@mui/material/styles';
import ModalCentered from "src/components/Modals/ModalCentered";
import IconButton from "@mui/material/IconButton";
import AlertModal from 'src/components/AlertModal/AlertModal'
import Chip from '@mui/material/Chip';
import GetMuiTheme from '../theme';
import NormalCard from '../Cards/NormalCard';
import ContactInfoCreateForm from "src/containers/Users/UserAccountDocument/ContactInfoCreateForm";
import SnackBar from '../SnackBar/SnackBar';
import { userService } from 'src/_services/users';
import SessionExpiredModal from '../AlertModal/SessionExpiredModal';

const PREFIX = 'ContactInfo';

const classes = {
    primaryBtn: `${PREFIX}-primaryBtn`,
    root: `${PREFIX}-root`,
    btnright: `${PREFIX}-btnright`,
    container: `${PREFIX}-container`,
    button: `${PREFIX}-button`,
    tooltip: `${PREFIX}-tooltip`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    tableEllips: `${PREFIX}-tableEllips`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.primaryBtn}`]: {
        border: "1px solid #F4F6F8",
        padding: "5px",
        borderRadius: "5px",
        color: "#223464",
        textTransform: "capitalize",
        outline: "none",
        "&:hover": {
            border: "1px solid #fff",
        },

    },

    [`& .${classes.root}`]: {
        margin: theme.spacing(2),
        '& .MuiTableHead-root': {
            background: 'blue'
        }
    },

    [`& .${classes.btnright}`]: {
        textAlign: 'end'
    },

    [`& .${classes.container}`]: {
        // minHeight: '100%',
        padding: '24px'

    },

    [`& .${classes.button}`]: {
        marginBottom: '1%',
        borderRadius: '14px 14px 0 0',
        boxShadow: 'none',
        textTransform: 'capitalize'
    },

    [`& .${classes.tooltip}`]: {
        cursor:'copy'
    },

    [`& .${classes.editdeletbtn}`]: {
        color: '#3363cb'
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1)
    },

    [`&.${classes.tableEllips}`]: {
        display:"flex",
      }
}));

const getMuiTheme = GetMuiTheme

export default function ContactInfo(props) {
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [accountID,setAccountID] = useState()
    const [formMode, setFormMode] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [component, setComponent] = useState("");
    const [titleModal, setTitleModal] = useState("");
    const [severity, setSeverity] = React.useState("");
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
        false
      );
      const [formData, setFormData] = useState(null);
      const [rowID, setRowID] = useState();
    
      const [contactInfoData, setContactInfoData] = useState([]);

    const [hover, setHover] = useState(false);
  const onHover = (value) => {
      setHover(true);
      navigator.clipboard.writeText(value);
    };
  
    const onLeave = () => {
      setHover(false);
    };

    const handleEditContact = (rowData) => {
        setFormData({
          name: rowData[0],
          email: rowData[1],
          mobileNumber: rowData[2],
          contactType: rowData[6],
        });
        setRowID(rowData[7]);
        openModalHandler("contact", "edit");
      };

    const getContactAccounts = () => {
        userService.getContactInfoAccounts(props.userID).then((res) => {
          if (res) {
            if (res.status === 200) {
              setContactInfoData(res.data);
            } else {
              if (res.status === 401 && res.data.name === "TokenExpiredError") {
                setOpenSessionExpiredModal(true);
              }
            }
          }
        });
      };

    const contactFormSubmit = (formData) => {
        // alert(JSON.stringify(values, null, 2))
        let data = {
          name: formData.name,
          email: formData.email,
          contact_no: formData.mobileNumber,
          contact_type: formData.contactType,
        };
    
        if (formMode === "create") {
          userService.createContactInfo(props.userID, data).then((res) => {
            if (res.status === 201) {
              getContactAccounts();
              setOpenModal(false);
              setOpenSnackBar(true);
              setSeverity("success");
              setSnackbarTitle("Contact added successfully");
            } else if (
              res.status === 401 &&
              res.data.name === "TokenExpiredError"
            ) {
              setOpenModal(false);
              setOpenSessionExpiredModal(true);
            } else {
              setSeverity("error");
              setSnackbarTitle("Something went wrong !");
              setOpenSnackBar(true);
            }
          });
        } else {
          data.id = rowID;
          userService.editContactInfo(props.userID, data).then((res) => {
            if (res.status === 200) {
              getContactAccounts();
              setOpenModal(false);
              setSeverity("success");
              setSnackbarTitle("Contact Updated");
              setOpenSnackBar(true);
            } else if (
              res.status === 401 &&
              res.data.name === "TokenExpiredError"
            ) {
              setOpenModal(false);
              setOpenSessionExpiredModal(true);
            } else {
              setSeverity("error");
              setSnackbarTitle("Something went wrong !");
              setOpenSnackBar(true);
            }
          });
        }
      };
  
    const customrender=((value)=>{
        return (
            <Root onClick={()=>{onHover(value)}} onMouseLeave={onLeave} className={classes.tableEllips} >
                <Tooltip  className={classes.tooltip} title={hover ? "Copied" :"Click to Copy"}>
            
                <Typography
                    variant='body2'
                    //   style={{ textTransform: 'capitalize' }}
                    noWrap
                    >
                    {value}
                </Typography>
                </Tooltip>
            </Root>
        );
    })
    const contactInfoHeading = [
        {
            name: "name",
            label: "Name",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => customrender(value)

            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => customrender(value)
            }
        },
        {
            name: "contact_no",
            label: "Mobile Number",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => customrender(value)

            }
        },
        {
            name: "contact_type",
            label: "Default",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Chip size="small" label={value} color={value==="Primary" ? "primary" : "default"}/>
                    );
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
                customBodyRender: ( value,tableMeta ,) => {
                    return  (
                        <span className={classes.editdeletbtn} >
                            <IconButton
                                className={classes.iconbtn}
                                aria-label="edit"
                                onClick={() => handleEditContact(tableMeta.rowData)}
                            >
                                <EditIcon />
                            </IconButton>

                            <IconButton
                                className={classes.iconbtn}
                                aria-label="delete"
                                onClick={() => handleConfirm(tableMeta.rowData[7])}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </span>
                    ) 
                }
            }
        },
        {
            name: "last_updated",
            label: "Last Updated",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "contact_type_code",
            label: "Contact",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
    ];

    const handleConfirm = (id) => {
        setDeleteConfirm(true);
        setAccountID(id)        
        // props.delete
    }


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  const contactDeleteHandler = (id) => {
    userService.deleteContactInfo(props.userID, id).then((res) => {
      if (res.status === 200) {
        setSeverity("success");
        setSnackbarTitle("contact deleted");
        setOpenSnackBar(true);
        getContactAccounts();
      } else {
        if (res.status === 401 && res.data.name === "TokenExpiredError") {
          setOpenSessionExpiredModal(true);
        } else {
          setSeverity("error");
          setSnackbarTitle("unable to delete contact");
          setOpenSnackBar(true);
        }
      }
    });
  };

    const handleDeleteTableRow = () => {
        contactDeleteHandler(accountID);
        setDeleteConfirm(false);
    }

      //get Demat accounts
  useEffect(() => {
    if (props.userID !== null) {
      getContactAccounts();
    }
  }, [props.userID]);


    const closeModalHandler = () => {
        setOpenModal(false);
      };
 
    const openModalHandler = (component, component_mode) => {
        setComponent(component);
        setFormMode(component_mode);
    
        if (component === "bankAccount") {
          setTitleModal("Add Bank Account");
          setSeverity("success");
          setSnackbarTitle("Bank Account Added Successfully");
        } else if (component === "contact") {
          if (component_mode === "create") {
            setTitleModal("Add Contact");
          } else {
            setTitleModal("Edit Contact");
          }
          // setSeverity("success");
          // setSnackbarTitle("Contact Information Added Successfully");
        } else {
          setTitleModal("Upload Document");
          setSeverity("success");
          setSnackbarTitle("Document uploaded Successfully");
        }
    
        setOpenModal(true);
      };
    

    return (
        <div>
             <NormalCard
            title="Additional Contact Details"
            btn={
              <Button
                color="primary"
                onClick={() => openModalHandler("contact", "create")}
                size="medium"
                type="submit"
                variant="outlined"
                className={classes.addButton}
                disabled={props.userID !== null ? false : true}
                // name="addDemat"
                // value=s"addDemat"
              >
                Add Contact
              </Button>
            }
          >
            <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    type={props.type}
                    data={contactInfoData}
                    headerSort={false}
                    columns={contactInfoHeading}
                    options={{
                        search: false,
                        viewColumns: false,
                        filter: false,
                        pagination: false,
                        download: true,
                       
                        print: false,
                        rowsPerPage: 5,
                        rowsPerPageOptions: [5, 10, 25],
                        selectableRows: "none",
                        textLabels: {
                            body: {
                                noMatch:  'No contacts found'
                            },
                        },
                        sortOrder: {
                            name: 'last_updated',
                            direction: 'asc'
                        },

                    }}
                />
                <AlertModal
                title="Are you sure you want to delete this contact?"
                open={deleteConfirm}
                toggle={() => setDeleteConfirm(false)}
                onConfirm={handleDeleteTableRow}
                openLoading = {props.openLoading}
            >
            </AlertModal>
            </ThemeProvider>
            </NormalCard>
            {component === "contact" ? (
        <ModalCentered
          title={titleModal}
          open={openModal}
          toggle={closeModalHandler}
          children={
            <ContactInfoCreateForm
              formSubmit={contactFormSubmit}
              mode={formMode}
              data={formData}
            />
          }
        />
      ) : null}
       <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
            <SessionExpiredModal open={openSessionExpiredModal} />
        </div>
    )
}
