import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import MUIDataTable from "mui-datatables";
import { Typography,Button } from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import { connect } from "react-redux";
import NormalCard from "src/components/Cards/NormalCard";
import IconButton from "@mui/material/IconButton";
import Page from "src/components/Page";
import SnackBar from "src/components/SnackBar/SnackBar";
import ModalCentered from "src/components/Modals/ModalCentered";
import AlertModal from "src/components/AlertModal/AlertModal";
// import AddSecondoryCreateFormAndDetails from "./AddSecondoryCreateFormAndDetails";
import DeleteIcon from "@mui/icons-material/Delete";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import GetMuiTheme from "src/components/theme";
import { userService } from "src/_services/users";
import Tooltip from "@mui/material/Tooltip";
import CreateSalesGroup from "./CreateSalesGroup";

const PREFIX = "Sales_group";

const classes = {
  tableEllips: `${PREFIX}-tableEllips`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  iconbtn: `${PREFIX}-iconbtn`,
  addButton: `${PREFIX}-addButton`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.tableEllips}`]: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "11rem",
    [theme.breakpoints.down("sm")]: {
      width: "8rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "11rem",
    },
  },
  [`& .${classes.addButton}`]: {
    textTransform: "capitalize",
  },
  [`& .${classes.editdeletbtn}`]: {
    color: "#3363cb",
    display: "flex",
  },
}));

const SalesGroup = (props) => {
  const [supportGroups, setSupportGroups] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [editRowData, setEditRowData] = useState(null);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [mode, setMode] = React.useState(null);
  const [spinner, setSpinner] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEditRowData(null);
    setSnackBarOpen(false);
  };

  var isAdmin = props.authReducer.auth.user.default_role_code;

  const handleShowClose = () => {
    setModalOpen(false);
  };

  const handleDeleteTableRow = () => {
    setSpinner(true);
    userService
      .deleteSalesGroup(deleteId)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          getAllGroups();
          setDeleteOpen(false);
          setSpinner(false);
          setSeverity("success");
          setSnackBarOpen(true);
          setSnackbarTitle("Group deleted");
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setDeleteOpen(false);
            setSpinner(false);
            setOpenSessionExpiredModal(true);
          } else {
            setDeleteOpen(false);
            setSpinner(false);
            setSeverity("error");
            setSnackbarTitle("Something Went Wrong!");
            setSnackBarOpen(true);
          }
        }
      })
      .catch((error) => {
        setDeleteOpen(false);
        setSpinner(false);
        setSeverity("error");
        setSnackbarTitle("Invalid Token");
        setSnackBarOpen(true);
      });
  };

  const getAllGroups = () => {
    setLoading(true);
    userService
      .getAllSalesGroups()
      .then((res) => {
        if (res) {
          console.log(res);
          if (res.status === 200) {
            setSupportGroups(res.data);
            setLoading(false);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setLoading(false);
              setOpenSessionExpiredModal(true);
            } else {
              setSeverity("error");
              setSnackbarTitle(res.data ? res.data : "Something Went Wrong!");
              setSnackBarOpen(true);
              setLoading(false);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllGroups();
  }, []);

  const getMuiTheme = GetMuiTheme;

  const columns = [
    {
      name: "group_id",
      label: "Id",
      options: {
        filter: false,
        sort: true,
        display: "excluded",
      },
    },
    {
      name: "team_lead",
      label: "Team Lead",
      options: {
        filter: false,
        sort: false,
        hint: "Who will be sent email followups",
      },
    },
    {
      name: "related_team_lead_id",
      label: "Team Lead",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
      },
    },
    {
      name: "related_team_members",
      label: "Team Members",
      options: {
        filter: false,
        sort: false,
        display: "excluded",
        hint: "Who will be sent email in cc with team lead followups",
      },
    },
    {
      name: "team_members",
      label: "Team Members",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          let data = value?.join(", ");

          return (
            <div className={classes.tableEllips}>
              <Tooltip title={data} disableInteractive>
                <Typography variant="body2" noWrap>
                  {data}
                </Typography>
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[5] === 1 || isAdmin === "ADMIN" ? (
            <span className={classes.editdeletbtn}>
              <IconButton
                aria-label="edit"
                disabled={isAdmin === "ADMIN_VIEWER"}
                onClick={() => handleEditGroup(tableMeta)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setDeleteOpen(true);
                  setDeleteId(tableMeta.rowData[0]);
                }}
                aria-label="delete"
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : null;
        },
      },
    },
  ];

  const handleEditGroup = async (rowData) => {
    setModalOpen(true);
    setMode("edit");
    setEditRowData({
      teamLead: rowData.rowData[2],
      teamMembers: rowData.rowData[3],
      id: rowData.rowData[0],
    });
    setModalTitle("Update Group");
  };

  const handleCreateGroup = () => {
    setModalOpen(true);
    setEditRowData(null);
    setMode("create");
    setModalTitle("Create Group");
  };

  const createGroup = (values) => {
    setLoading(true);
    if (mode === "edit") {
      userService.updateSalesGroup(values).then((res) => {
        setLoading(false);
        setModalOpen(false);
        setModalTitle();
        if (res.status === 200) {
          setSeverity("success");
          setSnackbarTitle("Group updated successfully!");
          setSnackBarOpen(true);
          getAllGroups();
        } else {
          setSeverity("error");
          setSnackbarTitle(res.data ? res.data : "Something Went Wrong!");
          setSnackBarOpen(true);
        }
      });
    } else {
      userService.createSalesGroup(values).then((res) => {
        setLoading(false);
        setModalOpen(false);
        setModalTitle();
        if (res.status === 200) {
          setSeverity("success");
          setSnackbarTitle("Group created successfully!");
          setSnackBarOpen(true);
          getAllGroups();
        } else {
          setSeverity("error");
          setSnackbarTitle(res.data ? res.data : "Something Went Wrong!");
          setSnackBarOpen(true);
        }
      });
    }
  };

  return (
    <Root>
      <Page title="Unlistedkart | Sales Groups">
        <NormalCard
          title={"Sales Groups"}
          subtitle={
            "Used to send reports to sales team for dealterm pending orders"
          }
          btn={
            <div style={{ display: "flex" }}>
              <Button
                className={classes.addButton}
                variant="contained"
                color="primary"
                size="medium"
                disabled={isAdmin === "ADMIN_VIEWER"}
                onClick={handleCreateGroup}
              >
                Create Group
              </Button>
            </div>
          }
        >
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              data={supportGroups}
              headerSort={false}
              columns={columns}
              options={{
                search: true,
                download: true,
                print: false,
                filter: true,
                viewColumns: false,
                rowsPerPage: 10,
                rowsPerPageOptions: [5, 10, 25],
                // responsive: 'scrollMaxHeight',
                selectableRows: "none",
                sortOrder: {
                  name: "last_updated",
                  direction: "desc",
                },
                textLabels: {
                  body: {
                    noMatch: loading
                      ? "Loading..."
                      : "Sorry, there are no matching data to display",
                  },
                },
              }}
            />
          </ThemeProvider>
        </NormalCard>
        <ModalCentered
          title={modalTitle}
          open={modalOpen}
          maxWidth={"md"}
          toggle={handleShowClose}
          children={
            <CreateSalesGroup
              data={editRowData}
              formSubmit={createGroup}
              mode={mode}
            />
          }
        />
        <SnackBar
          open={snackBarOpen}
          severity={severity}
          close={handleClose}
          snackbarTitle={snackbarTitle}
        />
        <AlertModal
          title="Are you sure you want to delete this group?"
          open={deleteOpen}
          setOpen={setDeleteOpen}
          toggle={() => setDeleteOpen(false)}
          onConfirm={() => handleDeleteTableRow()}
          openLoading={spinner}
        ></AlertModal>
        <SessionExpiredModal open={openSessionExpiredModal} />
      </Page>
    </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    force: state, // force state from reducer
    authReducer: state.authReducer,
  };
};

export default connect(mapStateToProps)(SalesGroup);
