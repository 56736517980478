import React, {  useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import {   Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import NormalCard from "src/components/Cards/NormalCard"
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment'
import {  Download,  InfoOutlined } from '@mui/icons-material';
import GetMuiTheme from 'src/components/theme';
import { securityService } from 'src/_services/security';
import { inventoryPriceService } from 'src/_services/inventoryPrice';
const PREFIX = 'InternalInventoryandPrice';

const classes = {
    tableEllipsCompany: `${PREFIX}-tableEllipsCompany`,
    root: `${PREFIX}-root`,
    tooltip: `${PREFIX}-tooltip`,
    green: `${PREFIX}-green`,
    gray: `${PREFIX}-gray`,
    container: `${PREFIX}-container`,
    editdeletbtn: `${PREFIX}-editdeletbtn`,
    btnsdltedt: `${PREFIX}-btnsdltedt`,
    footerbtn: `${PREFIX}-footerbtn`,
    footerbtnrit: `${PREFIX}-footerbtnrit`,
    iconbtn: `${PREFIX}-iconbtn`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.tableEllipsCompany}`]: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        width: '11rem',
        [theme.breakpoints.down('sm')]: {
            width: '8rem',
        },
        [theme.breakpoints.up('sm')]: {
            width: '11rem',
        },
    },

    [`& .${classes.root}`]: {
        margin:'1rem',
        '& .MuiTableHead-root': {
            background: 'blue',
            padding:'1rem'
        }
    },

    [`& .${classes.tooltip}`]: {
        cursor:'copy'
    },

    [`& .${classes.green}`]: {
        color: '#22ec22',
        width:'1rem'
    },

    [`& .${classes.gray}`]: {
        color: 'gray',
        width:'1rem'
    },

    [`& .${classes.container}`]: {
        padding: '24px'
    },

    [`& .${classes.editdeletbtn}`]: {
        color: '#3363cb'
    },

    [`& .${classes.btnsdltedt}`]: {
        marginRight: theme.spacing(1)
    },

    [`& .${classes.footerbtn}`]: {
        textAlign: 'right',
        width: '95%',
        margin: 'auto',
        marginBottom:theme.spacing(4),
        marginTop: theme.spacing(2),
        [theme.breakpoints.down(453)]: {
            textAlign: 'center',
        },
    },

    [`& .${classes.footerbtnrit}`]: {
        marginLeft: theme.spacing(2),
        // marginBottom:theme.spacing(2),
        [theme.breakpoints.down(453)]: {
            marginLeft: theme.spacing(0),
            marginTop: theme.spacing(2),
        },
    },

    [`& .${classes.iconbtn}`]: {
        padding: theme.spacing(0.8)
    }
}));

// const settings = require('../../settings');
const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});

const getMuiTheme =GetMuiTheme 


function sortByDate(a, b) {
    if (a.date < b.date) {
        return 1;
    }
    if (a.date > b.date) {
        return -1;
    }
    return 0;
}

const InternalInventoryandPrice = ({userRole}) => {
    const getPriceData =(()=>{
        inventoryPriceService.getInventoryPrice()
        .then(res => {
            if (res) {
                if (res.status === 200) {
                    let array = []
                    res.data.map((item)=>{
                        return(
                            array.push({
                                Date:moment(item.last_updated).format("YYYY-MM-DD"),
                                security_id:item.security_id,
                                Name:item.name,
                                Isin:item.isin,
                                Quantity:item.quantity,
                                Status:item.is_sold_online,
                                price:item.price,
                                "Price 0.5%":parseFloat(Number(item.price) + (Number(item.price) * 0.5/ 100)).toFixed(3),
                                "Price 1%":parseFloat(Number(item.price) + (Number(item.price) * 1 / 100)).toFixed(3),
                                "Price 2%":parseFloat(Number(item.price) + (Number(item.price) * 2 / 100)).toFixed(3),
                                "Price 3%":parseFloat(Number(item.price) + (Number(item.price) * 3 / 100)).toFixed(3),
                                "Price 4%":parseFloat(Number(item.price) + (Number(item.price) * 4 / 100)).toFixed(3),
                                "Price 5%":parseFloat(Number(item.price) + (Number(item.price) * 5 / 100)).toFixed(3),
                            })
                        )
                    })
                    const data =array.filter((item) =>  item.date !== 'Invalid date').sort(sortByDate)
                    setCsvData(data)
                } 
            }
        
                }).catch(error => console.log(error))
    })
    useEffect(()=>{
        getPriceData()
        getSecurityGroupNames()
    },[])





    const [securityTagData, setSecurityTagData] = useState([])

    const getSecurityGroupNames = () => {
        securityService.getSecurityGroups()
            .then(res => {
                if (res) {
                    if (res.status === 200) {
                        setSecurityTagData(res.data.data)
                    }  

                }
            }).catch(error => console.log(error))
    }

    const columns = [
        {
            name: "id",
            label: "Id",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "group_name",
            label: "Group name",
            options:{
                sort:false
            }
        },

        {
            name: "related_security_id",
            label: "No of Scrips",
            options: {
            sort:false,
            customBodyRender: (value) => {
                let newArray = [value];
                const groupedScrips =value?.length>0 ? newArray[0]?.split(",") :[];
                return (
                    <div className={classes.tableEllips} >
                      {groupedScrips.length}
                    </div>
                )
            }}
        },
        {
            name: "status",
            label: "Status",
            options: {
            sort:false,
            customBodyRender: (value) => {
                return (
                    <div className={classes.tableEllips} >
                        <Typography
                            variant='body2'
                            noWrap
                        >
                          { value === 1 ? "Active" : "Inactive"}
                        </Typography>
                    </div>
                )
            }}
        },
        {
            name: "related_security_id",
            label: "Download",
            options:{
                filter:false,
                sort:false,
                customBodyRender: (value,rowData) => {
                    return (
                    <Tooltip title="Download scrips Report" onClick={()=> value && handleDownload(rowData)}>
                      <Download sx={{ml:4,cursor:value ? 'pointer' : '', color:value ? '#000' :'lightgrey'}} />
                    </Tooltip>
                    )
                }
            }
        },
    ];

    const handleDownload=((data)=>{
        let newArray = [data.rowData[4]];
        const groupedScrips =data.rowData[4]?.length>0 ? newArray[0]?.split(",") :[];
        const filteredArray =groupedScrips?.length>0 ? csvData.filter(item => groupedScrips.map(Number).includes(item.security_id)) :[];
        downloadCSV(filteredArray,  `${data.rowData[1]}.csv`);
    })



const downloadCSV = (array, filename) => {
    const headers = Object.keys(array[0]);
    const csvContent = array.reduce((csvString, row) => {
        const values = Object.values(row);
        const rowString = values.map(value => `"${value}"`).join(',');
        return csvString + rowString + '\n';
      }, headers.join(',') + '\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    link.click();
    URL.revokeObjectURL(url);
  };
  const [csvData, setCsvData] = React.useState([]);

    return (
        <Root>
        <NormalCard title={
            <div style={{alignItems:'center',display:'flex'}}>
                Internal Inventory and Price
                <Tooltip  title="Hidden scrips will not be displayed here">
                    <InfoOutlined style={{fontSize:'14px', marginLeft:'4px'}}/>
                </Tooltip>
            </div>}
    
            // subtitle={"List of Scrips, Current Inventory and Price"}
        >
            <ThemeProvider theme={getMuiTheme}>
                <MUIDataTable
                    data={securityTagData}
                    headerSort={false}
                    columns={columns}
                    options={{
                        download:false,
                        viewColumns: false,
                        selectableRows: "none",
                        print: false,
                        delete: false,
                        rowsPerPage: 10,
                        rowsPerPageOptions: [5, 10, 25],
                        customSearch: (searchQuery, currentRow, columns) => {
                            let isFound = false;
                            currentRow.forEach(col => {
                                if (col && col.toString().toLowerCase().indexOf(searchQuery.toLocaleLowerCase()) >= 0) {
                                    isFound = true;
                                }
                            });
                            return isFound;
                        },
                    
                        sortOrder: {
                            name: 'last_updated',
                            direction: 'desc'
                        },
                    }}
                />
            </ThemeProvider>

        </NormalCard>
        </Root>
    );
}

export default InternalInventoryandPrice
