import React, { useEffect ,useState} from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import ModalCentered from "src/components/Modals/ModalCentered";
import ErrorFocus from "../../../components/ErrorFocus/ErrorFocus";
import {
  OutlinedInput,
 
  FormHelperText,
  Checkbox,
  Container,
  
  TextField,
 
  Typography,
  FormControlLabel,
  Box,
 
} from "@mui/material";
import loadingSpinner from 'src/images/loading-spinner.gif'

import * as Yup from "yup";
import { Formik } from "formik";
import ImageRotate from "src/containers/Profile/Rotate";

const PREFIX = 'DematCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  wrapper: `${PREFIX}-wrapper`,
  btnroot: `${PREFIX}-btnroot`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight:'4px'
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: 'relative',
    
  },

  [`& .${classes.btnroot}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'flex-end'
  }
}));


function DematCreateForm(props) {

  const [preview, setPreview] = useState();


  const timer = React.useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  function capitalizeFirstLetter(str) {
    return str.toLowerCase().split(' ').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');
  }
  const initialValues =props.data ?props.data :{
    dpWith: "",
    dpId: "",
    clientId: "",
    cmlCopy: "",
    id:"",
    primaryAccount:props.isPrimary ? true : false,
  }

  return (
    <StyledContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          dpWith: Yup.string().required("Required")
          .matches("^[A-Za-z 0-9]", "Invalid"),        
          dpId: Yup.string().required("Required")
            .matches("^[A-Za-z0-9]+$", "Invalid")        
            .min(8, "Minimum length should be 8")
            .max(8, "Maximum length should be 8"),
          clientId: Yup.string().required("Required")  
            .matches("^[A-Za-z0-9]+$", "Invalid")        
            .max(8, "Maximum length should be 8 digits")
            .min(8, "Minimum length should be 8 digits"),
            id:Yup.string().nullable(),
          cmlCopy: Yup.mixed().when('id', {
            is: value => value && value?.length > 0,
            then: Yup.mixed(),
            otherwise:Yup.mixed().required("A file is required")
            .test(
              "fileFormat",
              "Unsupported Format",
              value => value && SUPPORTED_FORMATS.includes(value.type)
            )
          .test(
            "fileSize",
            "Maximum file size is 10MB",
            value => value && value.size < 10242880
          )
          })
        })}
        onSubmit={(values) => {
          props.setLoading(true)
          props.formSubmit(values)
           
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
         
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="DP With*"
                id="dpWith"
                name="dpWith"
                onBlur={handleBlur}
                onChange={(e)=>setFieldValue('dpWith',capitalizeFirstLetter(e.target.value))}
                autoComplete="off"
                fullWidth
                value={values.dpWith}
                error={Boolean(touched.dpWith && errors.dpWith)}
                helperText={touched.dpWith && errors.dpWith}
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus />

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="DP ID*"
                id="dpId"
                name="dpId"
                onBlur={handleBlur}
                onChange={handleChange}
                fullWidth
                value={values.dpId}
                error={Boolean(touched.dpId && errors.dpId)}
                helperText={touched.dpId && errors.dpId}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus />

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Client ID*"
                id="clientId"
                name="clientId"
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                fullWidth
                value={values.clientId}
                error={Boolean(touched.clientId && errors.clientId)}
                helperText={touched.clientId && errors.clientId}
                aria-describedby="outlined-weight-helper-text"
                // onInput={(e) => {
                //   e.target.value = e.target.value.replace(
                //     /[^0-9]/g,
                //     ""
                //   );
                // }}
              />
              <ErrorFocus />

              {/* <FormControl
                variant="outlined"
                fullWidth={true}
                className={classes.inputMargin}
                name="cmlCopy"

              >
                <label className={classes.label}>CML Copy</label>
                <OutlinedInput
                  id="cmlCopy"
                  fullWidth
                  error={touched.cmlCopy && Boolean(errors.cmlCopy)}
                  helperText={touched.cmlCopy ? errors.cmlCopy : ""}
                  name="cmlCopy"
                  value={values.cmlCopy}
                  // className={classes.fileInput}
                  type="file"
                  onChange = {
                    (e) =>{
                      setFieldValue("cmlCopy",e.currentTarget.files[0])
                    }
                  }
                  placeholder="browser and upload  file"
                  aria-describedby="outlined-weight-helper-text"
                  endAdornment={
                    <InputAdornment position="end">
                      <label htmlFor="cmlCopy">
                        <IconButton aria-label="backup" color="primary">
                          <BackupIcon />
                        </IconButton>
                      </label>
                    </InputAdornment>
                  }
                  onBlur={handleBlur}
                  // onChange={handleChange}
                />
                <FormHelperText style={{ color: "#F44336" }}>
                  {touched.cmlCopy ? errors.cmlCopy : ""}
                </FormHelperText>
              </FormControl> */}

              <div>
                <Typography className={classes.label}>CML Copy*</Typography>
                <OutlinedInput
                  accept="image/*"
                  // className={classes.input}
                  id="icon-button-file"
                  name="cmlCopy"
                  fullWidth={true}
                  error={touched.cmlCopy && Boolean(errors.cmlCopy)}
                  // helperText={touched.cmlCopy ? errors.cmlCopy : ""}
                  onChange={(e) => {
                    setFieldValue("cmlCopy", e.currentTarget.files[0]);
                  }}
                  type="file"
                />
 {values.cmlCopy && !errors.cmlCopy ? (
                        <FormHelperText
                          onClick={() => {
                            const url = URL.createObjectURL(values.cmlCopy);
                            setPreview({
                              url: url,
                              type:
                                values.cmlCopy.type === "application/pdf"
                                  ? true
                                  : false,
                            });
                          }}
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {"Preview"}
                        </FormHelperText>
                      ):
                <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                  {touched.cmlCopy ? errors.cmlCopy : ""}
                </FormHelperText>}
              </div>
              <ErrorFocus />

              <FormControlLabel
                control={
                  <Checkbox
                    // error={Boolean(
                    //   errors.primaryAccount && touched.primaryAccount
                    // )}
                    // helperText={errors.primaryAccount && touched.primaryAccount}
                    disabled={props.isPrimary}
                    name="primaryAccount"
                    value={values.primaryAccount}
                    checked={values.primaryAccount}
                    id="primaryAccount"
                    aria-describedby="outlined-weight-helper-text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Set Primary"
              />
              <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                {errors.primaryAccount &&
                  touched.primaryAccount &&
                  errors.primaryAccount}
              </FormHelperText>
              <ErrorFocus />

              <Button
                color="primary"
                disabled={props.loading}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"

              >
                {props.loading === false ? values.id ?"Update" : "Add" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress}/> {values.id ?"Update" : "Add"} </>} 
              </Button>
            </Container>
          </form>
        )}
      </Formik>
      <ModalCentered
        title={"Preview"}
        open={preview}
        toggle={() => setPreview(false)}
        // maxWidth="md"
        fullScreen="true"
        children={
          <Box sx={{ textAlign: "center" }}>
            {preview?.type ? (
              <iframe
                title="iframe"
                style={{ width: "100%", height: "calc(100vh - 100px)" }}
                src={preview.url}
              />
            ) : (
              <ImageRotate
                alt="cool image"
                type="users"
                image={preview && preview.url}
              />
            )}
          </Box>
        }
      />
    </StyledContainer>
  );
}

export default DematCreateForm;
