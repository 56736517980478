import React, { useRef, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import SignatureCanvas from "react-signature-canvas";
import Dialog from "@mui/material/Dialog";
import {
  DialogContent,
  Typography,
  Button,
  Container,
  TextField,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
// import "./App.css";
import { serviceOrderService } from "src/_services/serviceOrder";
import Logo from "./unlistedkart_logo.png";
import CryptoJS from "crypto-js";
import { useLocation } from "react-router";
import { userService } from "src/_services/users";
import { userDocuments } from "src/_services/userDocuments";
import Cookies from "universal-cookie";

const PREFIX = "App";

const classes = {
  buyBtn: `${PREFIX}-buyBtn`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.buyBtn}`]: {
    fontFamily: "Poppins  !important",
    fontWeight: "500 !important",
    fontSize: "12px !important",
    lineHeight: "24px !important",
    minWidth: "100px",
    color: "white",
    border: "none",
    marginLeft: "1rem",
    padding: "5px 1rem !important",
    borderRadius: "10px !important",
    background: "#6D50CC !important",
    textAlign: "center",
    marginBottom: "1rem !important",
    "&:disabled": {
      color: "grey",
    },
  },
}));

export default function Empanelment() {
  const url = window.location.href;
  const signRef = useRef(null);
  const [id, setId] = useState(null);
  const [imageSouce, setImageSouce] = useState(null);
  const [openSignaturePopUp, setOpenSignaturePopUp] = useState(false);
  const [otpverified, setOtpVerified] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [signed, setSigned] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [snackbarTitle, setSnackbarTitle] = useState();
  const [initialValue, setInitialValue] = useState();
  const [completed, setCompleted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [signatureLength, setSignatureLength] = useState(0);
  const [file, setFile] = useState(null);
  const [cancelled, setCancelled] = useState(false);
  const cookies = new Cookies();

  const [showDealtermSucess, setShowDealtermSucess] = useState(false);
  const [template, setTemplate] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  let email = urlParams.get("email");
  let name = urlParams.get("name");



  if (email && email.includes("?")) {
    const [actualEmail, extraQueryString] = email.split("?");
    email = actualEmail;
    const extraParams = new URLSearchParams("?" + extraQueryString);
    name = extraParams.get("name") || name; // Update name if it's in the extraParams
  }

    const location = useLocation(); // Get the current location object
    const searchParams = new URLSearchParams(location.search); // Parse the query string
    // Extract the parameters
    const userId = searchParams.get("userId");
  function readBlobAsText(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function(event) {
        resolve(event.target.result);
      };
      reader.onerror = function(error) {
        reject(error);
      };
      reader.readAsText(blob);
    });
  }

  const verifyOtp = () => {
    const data = {
      otp: otp,
      email: decryptedEmail,
      order_id:id.startsWith('MU') ?id.replace('MU','') : id,
    };
    serviceOrderService.verifyOtpforDealterm(data).then((res) => {
      if (res.status === 201) {
        setInvalidOtp(true);
      } else if (res.status === 200) {
        cookies.set('signToken', res.data.Jwttoken.token, { path: '/', expires: new Date(new Date().getTime() + 60 * 60 * 1000 * 24 * 180) });
        setOtpVerified(true);
      }
    });
  };


  function decryptData(encryptedText, key) {
    // Convert key to 128-bit (16 bytes) for AES decryption
    let keyBytes = CryptoJS.enc.Utf8.parse(key);

    // Decrypt using AES
    let decrypted = CryptoJS.AES.decrypt(encryptedText, keyBytes, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });

    // Convert decrypted data to UTF-8 string
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
  let password = "ulksecretKey";
  let decryptedEmail = email && decryptData(email.replaceAll(" ", "+"), password);
  let decryptedName = name && decryptData(name.replaceAll(" ", "+"), password);
  function getCurrentDateTime() {
    const currentDateTime = new Date();

    const year = currentDateTime.getFullYear();
    const month = String(currentDateTime.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(currentDateTime.getDate()).padStart(2, "0");

    let hours = currentDateTime.getHours();
    const minutes = String(currentDateTime.getMinutes()).padStart(2, "0");
    const seconds = String(currentDateTime.getSeconds()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${formattedHours}:${minutes}:${seconds} ${ampm}`;

    return {
      date: formattedDate,
      time: formattedTime,
    };
  }

  const currentDateTime = getCurrentDateTime();

  const getUserDocuments = async (id) => {
    try {
      await userDocuments.getDocuments(id).then((res) => {
        if (res.status === 200) {
          const findSignedData = res.data.some(
            (item) =>
              item.code === "EMPANELMENT_DOCUMENT"
          );
          if (findSignedData) {
            setCompleted(true);
          }
        } else {
          setSnackbarTitle("Failed to load");
          setOpenSnackbar(false);
          setSeverity("error");
        }
      });
    } catch (error) {
      setSnackbarTitle("Failed to load");
      setOpenSnackbar(false);
      setSeverity("error");
    }
  };
  const resendOtp = async() => {
    setLoading(true);
    const data = {
      toMail: decryptedEmail,
      user_name:decryptedName,
      orderId: id,
      userId:userId
    };
    let res = userService.sendEmailforEmpanelment(data) 
      if (res.status === 200) {
        setSnackbarTitle("Otp sent successfully");
        setOpenSnackbar(true);
        setSeverity("success");
        setLoading(false);
      } else {
        setSnackbarTitle("Otp failed to send");
        setOpenSnackbar(false);
        setSeverity("error");
      }
    
  };


  useEffect(() => {
    const urlObject = new URL(url);
    const pathname = urlObject.pathname;
    const id = pathname.split("/")[2];
    setId(id);
    if(userId){
      generateEmpanelment(id)
      getUserDocuments(userId);
    }
    // eslint-disable-next-line
  }, [userId]);

  const applyDigitalSignature = () => {
    setTemplate(
      initialValue.replace(
        '<span style="text-indent: 0pt;text-align: left;" id="client"> ✍ </span>',
        `<div style="margin-top:-2rem;">
      <p  style="font-weight:bold;">${decryptedName}</p><p>(Digital Signature)<p><p>${currentDateTime.date} ${currentDateTime.time}</p></p></div>`
      )
    );
  };


  const generateEmpanelment =((id)=>{
    try {
      userService
        .getEmpanelmentDocument(id)
        .then(async (res) => {
          if (res) {
            if (res.status === 200) {
              const file = await readBlobAsText(res.data);
              setTemplate(file);
              setInitialValue(file);
            } else if (res.status === 400 ) {
                setCompleted(true)
            }else if (res.status === 401 ) {
                setCancelled(true)
              }
          }
        });
    } catch (error) {
      setSnackbarTitle("Failed to load");
      setOpenSnackbar(false);
      setSeverity("error");
    }


  })


  const generateEmpanelmentDocument =async () => {
    setLoading(true);
    let blobData = template.replaceAll('src=', '\nsrc=')
    try {
      let generateDoc = await  serviceOrderService.generateSignedEmpanelment({ data: blobData })
      if(generateDoc.status ===200){
          const file = new Blob([generateDoc.data], { type: "application/pdf" });
          setFile(file);
          const name = `Empanelment Document`;
          const formData = new FormData();
          formData.append("document_type", "EMPANELMENT_DOCUMENT");
          formData.append("document_name", name);
          formData.append("file", file);
          formData.append("notes", "");
          userDocuments
            .addDocument(userId, formData)
            .then((res) => {
              if (res) {
                if (res.status === 201) {
                  setShowDealtermSucess(true);
                  setSigned(true);
                } else {
                  setLoading(false);
                }
              } else {
                setLoading(false);
              }
            })
            .catch((error) => {
              setLoading(false);
            });
          }
    } catch (error) {
      setLoading(false);
      setSnackbarTitle("Something went wrong, please try after sometime");
      setOpenSnackbar(false);
      setSeverity("error");
    }
  };
  const handleClose = () => {
    setOpenSignaturePopUp(false);
  };

  const downloadPDF = () => {
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `Empanelment Document.pdf`;
    link.click();
    setLoading(false);
    setOpenSnackbar(true);
    setSeverity("success");
    setSnackbarTitle("Download success");
  };
  return (
    <Root
      style={{
        // maxWidth: "100vw",
        height: "auto",
        // background: '#fff'
      }}
    >
      <Container
        maxWidth={otpverified ? (id?.startsWith('MU') ?'lg' : "md") : "xs"}
        sx={{ width: { xs: "fit-content", md: "auto" } }}
      >
        {!decryptedName ? (
          <Typography textAlign={"center"} p={5}>
            Page not found
          </Typography>
        ) :cancelled ? (
          <Box textAlign={'center'}>
              <img src={Logo} alt="" />
          <Typography textAlign={"center"}>
          Oops, Invalid Id!
        </Typography>
            </Box>
        ): otpverified ? (
          <>
            <div
              style={{
                border: "1px solid lightGrey",
                padding: "1rem",
                margin: "1rem",
                width:id?.startsWith('MU') ? '1000px': "fit-content",
              }}
              dangerouslySetInnerHTML={{ __html: template }}
            ></div>
            {signed ? (
              <button className={classes.buyBtn} onClick={downloadPDF}>
                Download
              </button>
            ) : (
              <div
                style={
                  {
                    // width:'100vw',
                    //  padding:20,
                    //  display:'flex',
                    //  justifyContent:"end",
                    //  background:'#fff'
                  }
                }
              >
                <button
                  className={classes.buyBtn}
                  onClick={() => {
                    setSignatureLength(0);
                    setOpenSignaturePopUp(true);
                  }}
                >
                  {imageSouce ? "Re-sign" : "Sign now"}
                </button>
                <button
                  className={classes.buyBtn}
                  onClick={applyDigitalSignature}
                >
                  Apply Digital Signature
                </button>

                <button
                  className={classes.buyBtn}
                  disabled={
                    (imageSouce === null &&
                      template?.includes(
                        '<span style="text-indent: 0pt;text-align: left;" id="client"> ✍ </span>'
                      )) ||
                    loading
                  }
                  onClick={() => {
                    // const file = new Blob([template], { type: "application/pdf" });
                    // const fileURL = URL.createObjectURL(file);
                    // const link = document.createElement('a');
                    // link.href = fileURL
                    // link.download = `Invoice.pdf`;
                    // link.click();
                    generateEmpanelmentDocument();
                  }}
                >
                  Complete
                </button>
              </div>
            )}
          </>
        ) : completed ? (
          <div>
            <Box display="flex" justifyContent={"center"}>
              <img src={Logo} alt="" />
            </Box>
            <p style={{ textAlign: "center" }}>
              Hey {name ? decryptedName : ""},
            </p>
            <p style={{ textAlign: "center" }}>
              {" "}
              Agreement has been signed already!
            </p>
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <Box display="flex" justifyContent={"center"}>
              <img src={Logo} alt="" />
            </Box>
            <Typography>Enter OTP sent to email </Typography>
            <TextField
              size="small"
              value={otp}
              onChange={(e) => {
                setOtp(e.target.value);
                setInvalidOtp(false);
              }}
              name="otp"
              placeholder="OTP"
              type="number"
            />
            {invalidOtp ? (
              <Typography style={{ color: "red", fontSize: "12px" }}>
                Invalid Otp
              </Typography>
            ) : null}

            <Box
              textAlign="center"
              display="flex"
              justifyContent={"space-between"}
              gap={2}
            >
              <Button
                onClick={resendOtp}
                disabled={loading || !email}
                variant="outlined"
                sx={{
                  marginTop: "8px",
                  width: "150px",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                Resend OTP
              </Button>
              <Button
                onClick={verifyOtp}
                disabled={otp.length !== 6 || invalidOtp || !email}
                variant="outlined"
                sx={{
                  marginTop: "8px",
                  width: "150px",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                Verify
              </Button>
            </Box>
          </div>
        )}
      </Container>

      <Dialog
        sx={{
          "& .MuiPaper-root": {
            // rotate:'270deg'
          },
        }}
        onClose={handleClose}
        open={openSignaturePopUp}
      >
        <DialogContent style={{ padding: "0 10px", margin: "0" }}>
          <h5 style={{ margin: "10px 0" }}>Add Signature</h5>

          <SignatureCanvas
            ref={signRef}
            penColor="black"
            onEnd={() => {
              const length = signRef?.current?.toDataURL();
              setSignatureLength(length.length);
            }}
            canvasProps={{
              width: "300",
              height: "100",
              style: { border: "1px dashed #000" },
            }}
          />
          {signatureLength < 3000 && signatureLength !== 0 && (
            <Typography sx={{ fontSize: "12px", color: "red" }}>
              Required valid signature
            </Typography>
          )}
          <Root>
            <button
              className={classes.buyBtn}
              onClick={() => {
                signRef.current.clear();
                setSignatureLength(0);
              }}
            >
              Clear
            </button>
            <button
              className={classes.buyBtn}
              disabled={signatureLength < 3000}
              onClick={() => {
                let imageUrl = signRef.current.toDataURL();
                setImageSouce(imageUrl);
                setTemplate(
                  initialValue.replace(
                    '<span style="text-indent: 0pt;text-align: left;" id="client"> ✍ </span>',
                    `<img style="object-fit:contain;height:50px;margin-top:-2rem;" src="${imageUrl}" height="30" width="220" >`
                  )
                );
                setOpenSignaturePopUp(false);
              }}
            >
              Apply
            </button>
          </Root>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        onClose={handleClose}
        open={showDealtermSucess}
      >
        <DialogContent style={{ textAlign: "center" }}>
          <div
            style={{
              textAlign: "center",
              margin: "1rem",
              // border:"1px dashed black",
              overflow: "none",
            }}
          >
            <h3>Thank you for completing the agreement signing.</h3>
          </div>
          <button
            style={{ textAlign: "center" }}
            onClick={() => {
              setShowDealtermSucess(false);
              // window.close()
            }}
          >
            Close
          </button>
        </DialogContent>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={() => setOpenSnackbar(false)}
          severity={severity}
        >
          {snackbarTitle}
        </Alert>
      </Snackbar>
    </Root>
  );
}
