import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import { Typography, Link } from "@mui/material";

import {  ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { connect } from 'react-redux';
import NormalCard from "src/components/Cards/NormalCard";
import IconButton from "@mui/material/IconButton";
import Page from "src/components/Page";
import SnackBar from "src/components/SnackBar/SnackBar";
import ModalCentered from "src/components/Modals/ModalCentered";
import AlertModal from 'src/components/AlertModal/AlertModal'
import { additionalTradeService } from 'src/_services/additionalTrade';
import AddSecondoryCreateFormAndDetails from "./AddSecondoryCreateFormAndDetails";
import moment from 'moment'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from "src/components/theme";

const PREFIX = 'Index';

const classes = {
  tableEllips: `${PREFIX}-tableEllips`,
  root: `${PREFIX}-root`,
  btnright: `${PREFIX}-btnright`,
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  button: `${PREFIX}-button`,
  editdeletbtn: `${PREFIX}-editdeletbtn`,
  btnsdltedt: `${PREFIX}-btnsdltedt`,
  iconbtn: `${PREFIX}-iconbtn`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.tableEllips}`]: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "11rem",
    [theme.breakpoints.down("sm")]: {
      width: "8rem",
    },
    [theme.breakpoints.up("sm")]: {
      width: "11rem",
    },
  },

  [`& .${classes.root}`]: {
    margin: theme.spacing(2),
    "& .MuiTableHead-root": {
      background: "blue",
    },
  },

  [`& .${classes.btnright}`]: {
    textAlign: "end",
  },

  [`& .${classes.container}`]: {
    padding: "24px",
  },

  [`& .${classes.popDownBtn}`]: {
    marginTop: '-11px',
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]:{
      fontSize:'0.65rem'
    },
    borderRadius: "0 16px 16px 0",
    padding: "4px 8px",
  },

  [`& .${classes.button}`]: {
    marginBottom: "1%",
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
  },

  [`& .${classes.editdeletbtn}`]: {
    color: "#3363cb",
    display: 'flex'
  },

  [`& .${classes.btnsdltedt}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.iconbtn}`]: {
    padding: theme.spacing(0.8),
  }
}));

const AddSecondoryTrade = (props) => {

  const [secondaryTrade, setSecondaryTrade] = useState([])
  const [modalOpen, setModalOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [modalContainer, setModalContainer] = React.useState("");
  const [loading, setLoading] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [formMode, setFormMode] = useState(null)
  const [editRowData, setEditRowData] = useState(null)
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [rowId, setRowId] = useState(null)
  const [spinner, setSpinner] = useState(false)
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)




  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  var modifiedBy = props.authReducer.auth.user.user_unique_code;
  var isAdmin = props.authReducer.auth.user.default_role_code;
  const handleOpenModal = (data, mode) => {
    if (mode === "Add") {
      setFormMode("create")
      setModalContainer(data);
      setTitleModal("Add Secondary Trade");
      setModalOpen(true);
    }
    else if (mode === "edit") {
      setFormMode("edit")
      setModalContainer("Add");
      setTitleModal("Edit Secondary Trade");
      setModalOpen(true);
    }
    else if (mode === "details") {
      setModalContainer(data);
      setTitleModal("Secondary Trade Details");
      setModalOpen(true);
    }

  };

  const handleShowClose = () => {
    setModalOpen(false);
  };

  const submitHandle = (values) => {
    // setBtnLoading(true)
    // const validPrice = values.price.toFixed(2)
    const postSecondaryTradeData = {
      id:rowId,
      fund_name:values.fund_name,
      transfer_type:values.transfer_type.toUpperCase(),
      commitment_amount:values.commitment_amount,
      resipual_capital:values.resipual_capital,
      price:values.price,
      remarks:values.remarks,
    }

    if(formMode === "create"){ 
      additionalTradeService.postSecondaryTrade(postSecondaryTradeData).then(res =>{  
        if(res.status === 201){
          getAdditionalTradefromAPI();
          setModalOpen(false);            
          setBtnLoading(false)
          setSeverity("success")
          setSnackbarTitle("Added Successfully")
          setSnackBarOpen(true);
        }       
      }).catch(error=>console.log(error))
    }  
    else if(formMode === "edit"){
      additionalTradeService.updateSecondaryTrade(postSecondaryTradeData).then(res =>{
        if(res.status===200){
          getAdditionalTradefromAPI();
          setModalOpen(false);            
          setBtnLoading(false)
          setSeverity("success")
          setSnackbarTitle("Updated Successfully")
          setSnackBarOpen(true);  
        } 
      }).catch(error=>console.log(error))
    }  
  }    
  
  const handleDeleteTableRow = () => { 
    setSpinner(true)
    additionalTradeService.deleteSecondaryTradeByID(deleteId)
      .then(res => {
        if (res.status === 200) {
          getAdditionalTradefromAPI();
          setDeleteOpen(false)
          setSpinner(false)
          setSeverity("success")
          setSnackBarOpen(true)
          setSnackbarTitle("Secondary Trade deleted")
        
        }
        else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setDeleteOpen(false);
            setSpinner(false)
            setOpenSessionExpiredModal(true)
          }else{
            setDeleteOpen(false)
            setSpinner(false)
            setSeverity("error")
            setSnackbarTitle("Something Went Wrong!")
            setSnackBarOpen(true)
          
          }
        }
      }).catch(error => {
        setDeleteOpen(false)
        setSpinner(false)
        setSeverity("error")
        setSnackbarTitle("Invalid Token")
        setSnackBarOpen(true)
       
      })
  };


  const getAdditionalTradefromAPI = () => {
    setLoading(true)
    additionalTradeService.getSecondaryTradeList()
      .then(res => {
        if (res) {
          

          if (res.status === 200) {

            setSecondaryTrade(res.data)
            setLoading(false)
          }
          else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setLoading(false)
              setOpenSessionExpiredModal(true)
            }else{
              setSeverity("error")
              setSnackbarTitle("Something Went Wrong!")
              setSnackBarOpen(true);
              setLoading(false)
            }
          }
        }
      }).catch(error => {
        console.log(error)
        setLoading(false)
      })
  }

  useEffect(() => {
    getAdditionalTradefromAPI();
  }, [])


  const getMuiTheme = GetMuiTheme

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "fund_name",
      label: "Fund Name",
      options: {
        hint: "Name of the Actual Fund",
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {

          return (
            <div className={classes.tableEllips}>
              <Link
                onClick={() => {
                  handleOpenModal(tableMeta.rowData, "details");
                }}
                component="button"
                variant="subtitle1"
                style={{ textTransform: 'capitalize', textDecoration:'none' }}
              >
                {value}
              </Link>
            </div>
          );
        },
      },
    },
    {
      name: "transfer_type",
      label: "Transfer Type",
      options: {
        hint: 'Type of Trade for Unlistedkart',
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <div className={classes.tableEllips}>
              <Typography variant="body2" noWrap>
                {value}
              </Typography>
            </div>
          );
        },
      },
    },
    {
      name: "commitment_amount",
      label: "Commitment Amount",
      options: {
        hint: "Expected Amount from/to Client",
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const validPrice = parseFloat(value)
          return (
            <Typography>
              {validPrice.toFixed(2)}
            </Typography>
          )
        }
      },
    },
    {
      name: "resipual_capital",
      label: "Residual Capital",
      options: {
        hint: "Amount remaining at the End of the Trade",
        filter: false,
        sort: false,
      },
    },
    {
      name: "created_user_name",
      label: "Owned By",
      options: {
        hint: 'Internal RM who owns the Folios',
        filter: false,
        sort: false,
      },
    },
    {
      name: "price",
      label: "Price",
      options: {
        hint: "Price of the Folios",
        filter: false,
        sort: false,
        customBodyRender: (value) => {
          const validPrice = parseFloat(value)
          return (
            <Typography>
              {validPrice.toFixed(2)}
            </Typography>
          )
        }
      },
    },
    {
      name: "last_updated",
      label: "Last Updated",
      options: {
        hint: "Update Secondary Trade",
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          let valueMoment= moment(value).format("DD-MM-YYYY LTS")
          return (             
            <Typography
                variant='body2'
                noWrap
            >{valueMoment}</Typography>
          )
        }
      },
    },
    {
      name: "remarks",
      label: "Remarks",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },

    {
      name: "",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta) => {
          return tableMeta.rowData[10] === modifiedBy || isAdmin === "ADMIN" ? (
            <span className={classes.editdeletbtn}>
              <IconButton
                disabled={isAdmin === "ADMIN_VIEWER"}
                className={classes.iconbtn}
                aria-label="edit"
                onClick={() => { handleEditSecondary(tableMeta.rowData); setRowId(tableMeta.rowData[0]) }}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                className={classes.iconbtn}
                disabled={isAdmin === "ADMIN_VIEWER"}
                aria-label="delete"
                onClick={() => { setDeleteOpen(true); setDeleteId(tableMeta.rowData[0]) }}
              >
                <DeleteIcon />
              </IconButton>
            </span>
          ) : null;
        },
      },
    },

    {
      name: "modify_user",
      label: "modify_user",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];

  const handleEditSecondary = async (rowData) => {

    await setFormMode("edit");
    setEditRowData(rowData)
    handleOpenModal(rowData, "edit")
  }




  return (
    <Root>
    <Page title="Unlistedkart | Secondary-Trade">
      <NormalCard
        title={"Secondary Trade"}
        // subtitle={"List of Secondaries/AIFS Available"}
        // btn={
        //   <Button
        //     color="primary"
        //     className={classes.popDownBtn}
        //     size="medium"
        //     onClick={() => handleOpenModal("Add", "Add")}
        //     variant="contained"
        //   >
        //     Add Secondary Trade
        //   </Button>
        // }
      >
        <ThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            data={secondaryTrade}
            headerSort={false}
            columns={columns}
            options={{
              search: false,
              download: true,
              print: false,
              filter: false,
              viewColumns: false,
              rowsPerPage: 10,
              rowsPerPageOptions: [5, 10, 25],
              // responsive: 'scrollMaxHeight',
              selectableRows: "none",
              sortOrder: {
                name: 'last_updated',
                direction: 'desc'
              },
              textLabels: {
                body: {
                  noMatch: loading ?
                    'Loading...' : 'Sorry, there are no matching data to display'
                },
              }
            }}
          />
        </ThemeProvider>
      </NormalCard>
      <ModalCentered
        style={modalContainer !== "Add" ? { padding: 0 } : {}}
        title={titleModal}
        open={modalOpen}
        toggle={handleShowClose}
        children={
          <AddSecondoryCreateFormAndDetails
            spinner={btnLoading}
            formSubmit={submitHandle}
            type={modalContainer}
            mode={formMode}
            data={editRowData}
          />
        }
      />
      <SnackBar
        open={snackBarOpen}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <AlertModal
        title="Are you sure you want to delete this?"
        open={deleteOpen}
        setOpen={setDeleteOpen}
        toggle={() => setDeleteOpen(false)}
        onConfirm={() => handleDeleteTableRow()}
        openLoading={spinner}
      >
      </AlertModal>
      <SessionExpiredModal
        open={openSessionExpiredModal}

      />
    </Page>
    </Root>
  );
};

const mapStateToProps = state => {
  return ({
    force: state, // force state from reducer
    authReducer: state.authReducer,
  })
};

export default connect(mapStateToProps)(AddSecondoryTrade);

