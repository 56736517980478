import React, { useEffect,useState } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  OutlinedInput,
  FormHelperText,
  Container,
  Typography,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import Autocomplete from '@mui/material/Autocomplete';
import ErrorFocus from "src/components/ErrorFocus/ErrorFocus";
import { getLookUpDataService } from "src/_services/lookUp"
import loadingSpinner from 'src/images/loading-spinner.gif'
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import moment from "moment";
import ImageRotate from "../Profile/Rotate";
import ModalCentered from "src/components/Modals/ModalCentered";

const PREFIX = 'DocumentUploadForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  paddingInput: `${PREFIX}-paddingInput`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  }
}));


function DocumentUploadForm(props) {


  const [documentLookUpData, setDocumentLookUpData] = React.useState([])
  let loading =props.loading
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
  const [docName,setDocName] = React.useState('')
  const [preview, setPreview] = useState();

  useEffect(() => {
    getLookUpDataService.getServiceOrderDocumentLookUp()
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            const item = res.data[0].service_order_doc_type.map((data) => {
              return {
                id: data.id,
                code: data.code,
                lable:props.order_type === "Buy" ? (data.description === "Share Transferred Proof" ? "Share Credit Proof" :data.description === "Payment Received Proof" ? "Payment Transferred Proof": data.description) : data.description
              }
            })
            let newItem  = item.filter((items)=>{
              return (items.code !=='LETTER_OF_INTENT' && items.code !=='STAGE_ONE_DOCUMENT' && items.code !=='STAGE_TWO_DOCUMENT')
            })
            let onlineOrderItem=[
              {
                id: 1,
                code: 'SHARE_TRANSFERED_PROOF',
                lable: "Share Transferred Proof"
              },
              {

                id: 2,
                code: 'PAYMENT_RECEIVED_PROOF',
                lable: 'Payment Received Proof'
              }
            ]
           if(props.is_purchased_online){
            setDocumentLookUpData(onlineOrderItem)
           }else if(props.isNSE){
            setDocumentLookUpData(item)
            }else if(props.order_type ==="Sell" && !props.isNSE ){
            setDocumentLookUpData(newItem)
           }else{
            setDocumentLookUpData(newItem)           }
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }
        }
      })
  }, [])



  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  return (
    <StyledContainer>
      <Formik
        initialValues={{
          document_type: "",
          file: "",
          document_name: "",
          related_service_order_id: props.service_order_id,
          isVerified:false,
          referenceId:"",
          amount:"",
          date:moment(new Date()).format("YYYY-MM-DD")
        }}
        validationSchema={Yup.object().shape({
          document_type: Yup.string().required("Required"),
          // file:Yup.string().required("Required"),
          document_name: Yup.string().when("document_type", {
            is: "OTHERS", //just an e.g. you can return a function
            then: Yup.string().required("Required"),
            otherwise: Yup.string(),
          }),
          amount:Yup.string().when("document_type", {
            is: (type) => (type  === 'STAGE_ONE_DOCUMENT' || type === 'STAGE_TWO_DOCUMENT'), //just an e.g. you can return a function
            then: Yup.string().required("Required"),
            otherwise: Yup.string(),
          }),
          date:Yup.string().when("document_type", {
            is: (type) => (type  === 'STAGE_ONE_DOCUMENT' || type === 'STAGE_TWO_DOCUMENT'), //just an e.g. you can return a function
            then: Yup.string().required("Required"),
            otherwise: Yup.string(),
          }),
          referenceId: Yup.string().when( "isVerified", {
            is: true,
            then: Yup.string().required("Required"),
            otherwise: Yup.string().nullable(),
          }),
          otp:Yup.string().when("document_type", {
            is: (type) => (type  === 'DIGITAL_COUNTER_SIGNED_DEAL_TERM'), //just an e.g. you can return a function
            then: Yup.string().required("Required"),
            otherwise: Yup.string(),
          }),
          file: Yup
          .mixed()
          .required("A file is required")
          .test(
            "fileFormat",
            "Unsupported Format",
            value => value && SUPPORTED_FORMATS.includes(value.type)
          )
          .test(
            "fileSize",
            "Maximum file size is 10MB",
            value => value && value.size < 10242880
          )
        })}
        onSubmit={(values) => {
          props.formSubmit(values,docName)

        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Autocomplete
                    id="document_type"
                    onInputChange={(e,value)=>{
                      if(value !=="Others"){
                        setFieldValue("document_name", value);
                      }else{
                        setFieldValue("document_name", '');
                      }   
                    }}
                    // defaultValue = {currentAssign[0]}
                    options={documentLookUpData}
                    getOptionLabel={(option) => option.lable}
                    onChange={(e, value) => {
                      if (value !== null) {
                        setFieldValue("document_type", value.code);
                        setDocName(value.lable)
                        if(value.code === 'PAYMENT_RECEIVED_PROOF' || value.code === 'SHARE_TRANSFERED_PROOF'){
                        setFieldValue("isVerified", true);
                        }else{
                          setFieldValue("isVerified", false);
                        }
                      } else {
                        setFieldValue("document_type", "");
                      }
                    }}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.document_type && errors.document_type)}
                        helperText={touched.document_type && errors.document_type}
                        name="document_type"
                        label="Document Type *"
                        fullWidth
                        className={classes.inputMargin}
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                {values.document_type === "OTHERS" ?
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="document_name"
                      className={classes.inputMargin}
                      id="document_name"
                      value={values.document_name}
                      label="Document Name * "
                      fullWidth
                      variant="outlined"
                      error={Boolean(touched.document_name && errors.document_name)}
                      helperText={touched.document_name && errors.document_name}
                      autoComplete="off"
                    />
                  </Grid>
                  : (values.document_type === "PAYMENT_RECEIVED_PROOF" || values.document_type === "SHARE_TRANSFERED_PROOF")   ?
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="referenceId"
                    className={classes.inputMargin}
                    id="referenceId"
                    value={values.referenceId}
                    label={values.document_type === "PAYMENT_RECEIVED_PROOF" ? "Payment Proof Id" :"Share Transferred Proof Id" }
                    fullWidth
                    variant="outlined"
                    error={Boolean(touched.referenceId && errors.referenceId)}
                    helperText={touched.referenceId && errors.referenceId}
                    autoComplete="off"
                  />
                </Grid>:<></>}
                {
                  ( (values.document_type === "STAGE_ONE_DOCUMENT" || values.document_type === "STAGE_TWO_DOCUMENT") && props.isNSE) ?
                    <Grid container spacing={2}>
                  <Grid item xs={6}>
                  <TextField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="amount"
                    type="number"
                    className={classes.inputMargin}
                    id="referenceId"
                    value={values.amount}
                    label={"Amount"}
                    fullWidth
                    variant="outlined"
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                    autoComplete="off"
                  />
                  </Grid>
                  <Grid item xs={6}>
                   <TextField
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="date"
                    type="date"
                    className={classes.inputMargin}
                    id="referenceId"
                    // InputProps={{ inputProps: { max: `${moment(today).format("YYYY-MM-DD")}` } }}
                    value={values.date}
                    label={"Date of Payment" }
                    fullWidth
                    variant="outlined"
                    error={Boolean(touched.date && errors.date)}
                    helperText={touched.date && errors.date}
                    autoComplete="off"
                  />
                  </Grid>
                  </Grid>
                  :null
                }
               
              </Grid>
              <div>
                <Typography className={classes.label}>Upload File</Typography>
                <OutlinedInput
                  accept="image/*"
                  // className={classes.input}
                  id="file"
                  name="file"
                  fullWidth={true}
                  onChange={(e) => {
                    setFieldValue("file", e.currentTarget.files[0]);
                  }}
                  type="file"
                  error={touched.file && Boolean(errors.file)}
                  helperText={touched.file ? errors.file : ""}
                />
                {values.file && !errors.file ? (
                        <FormHelperText
                          onClick={() => {
                            const url = URL.createObjectURL(values.file);
                            setPreview({
                              url: url,
                              type:
                                values.file.type === "application/pdf"
                                  ? true
                                  : false,
                            });
                          }}
                          style={{
                            textDecoration: "underline",
                            fontWeight: "bold",
                          }}
                        >
                          {"Preview"}
                        </FormHelperText>
                      ):
                <FormHelperText style={{ color: "#F44336", paddingLeft: 16, }}>
                  {touched.file ? errors.file : ""}
                </FormHelperText>
        }
              </div>
              <ErrorFocus />

              <Button
                color="primary"
                disabled={loading || isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Upload" : <><img alt="loader" src={loadingSpinner} className={classes.buttonProgress} /> Upload </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal
        open={openSessionExpiredModal}
      />
       <ModalCentered
        title={"Preview"}
        open={preview}
        toggle={() => setPreview(false)}
        // maxWidth="md"
        fullScreen="true"
        children={
          <Box sx={{ textAlign: "center" }}>
            {preview?.type ? (
              <iframe
                title="iframe"
                style={{ width: "100%", height: "calc(100vh - 100px)" }}
                src={preview.url}
              />
            ) : (
              <ImageRotate
                alt="cool image"
                type="users"
                image={preview && preview.url}
              />
            )}
          </Box>
        }
      />
    </StyledContainer>
  );
}

export default DocumentUploadForm;
