import React, { useState ,useEffect} from "react";
import { styled } from '@mui/material/styles';
import Page from "src/components/Page";
import SnackBar from "src/components/SnackBar/SnackBar";
import moment from 'moment'
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link} from "@mui/material";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ModalCentered from "src/components/Modals/ModalCentered";
import GroupSelectorCreateForm from "./GroupSelectorCreateForm";
import NormalCard from "src/components/Cards/NormalCard";
import { ThemeProvider } from "@mui/material/styles";

import ChannelPartnersListModel from "./ChannelPartnersListModel";
import AlertModal from 'src/components/AlertModal/AlertModal'
import EditIcon from '@mui/icons-material/Edit';
import { ChannelGroupService } from "src/_services/groupSelector";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from "src/components/theme";

const PREFIX = 'GroupSelector';

const classes = {
  root: `${PREFIX}-root`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  table: `${PREFIX}-table`,
  tableCell: `${PREFIX}-tableCell`,
  gridFlex: `${PREFIX}-gridFlex`,
  deletebtn: `${PREFIX}-deletebtn`,
  iconbtn: `${PREFIX}-iconbtn`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    // paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 16px 16px 0",
    padding: "10px",
  },

  [`& .${classes.table}`]: {
    minWidth: 340,
  },

  [`& .${classes.tableCell}`]: {
    paddingBottom: 5,
    paddingTop: 5,
    color: "#314b8f",
  },

  [`& .${classes.gridFlex}`]: {
    display: "flex",
    justifyContent: "space-between",
  },

  [`& .${classes.deletebtn}`]: {
    padding:'0px'
  },

  [`& .${classes.iconbtn}`]: {
    padding: theme.spacing(0.8)
  }
}));


export default function CreateServiceOrder() {

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [titleModal, setTitleModal] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [name, setName] = React.useState("");
  const [fromMode, setFormMode ] = useState(null)
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [rowID , setRowID ] = useState(null)
  const rowData =[];
  // const [selectedUsers, setSelectedUsers] = useState([])
  // const [groupMembers, setGroupMembers] = useState(null)
  const groupMembers =[]
  const [isDropdown , setIsDropdown] = useState("")
  // const [editrowID, setEditrowID] = useState("")
  // const [editFormData, setEditFormData] = useState("")
  const [groupId , setGroupId] = useState(null)
  const [spinner,setSpinner] = useState(false)
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


  

  const handleClose = ( reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleShow = (name, rowID) => {
    if (name === "add") {
      setName(name);
      setIsDropdown("YES")
      setFormMode("create")
      setTitleModal("Create Group");
      setShow(true);
    } 
    else if(name === "edit"){
      // getgroupDataById(rowID);
      setName(name);
      setFormMode("EditNamePercentage")
      setTitleModal("Edit Group");
      setShow(true);
      setIsDropdown("NO")
    }
    else if(name === "channelPartnerList") {
      setName(name);
      setShow(true);
      // setEditrowID(rowID)
      // getgroupDataById(rowID);
      setFormMode("edit")
      setTitleModal("External RM List");
      // setShow(true);
    }
  };


  // const  getgroupDataById = (rowID) =>{
  //   ChannelGroupService.getGroupSelectoById(rowID)
  //   .then(res => {
  //     if(res){
  //       if(res.status === 200){
  //         setRowData(res.data[0])
  //         // setShow(true);
  //         // setEditFormData(res.data)
  //         const grpMembers = res.data[0].group_members ? res.data[0].group_members.map(item => item.user_unique_code) : []
  //         setGroupMembers(grpMembers)
  //       }else{
  //         if (res.status === 401 && res.data.name === "TokenExpiredError") {
  //           setOpenSessionExpiredModal(true)
  //         }
  //       }
  //     }
  //   })
  // }

  const handleShowClose = () => {
    setShow(false);
  };

  const [groupData, setGroupData] = React.useState([]);
  const [loading, setLoading ] = useState(false)


  const getGroupSelectorDatafromAPI = () => {
    setLoading(true);
    ChannelGroupService.getCPgroupsData().then((res) => {
      if (res) {
        if (res.status === 200) {
          setGroupData(res.data);
          setLoading(false);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
            setLoading(false);
          }
        }
      }
    })
    .catch((error) => {
      setLoading(false);
    });
  };
  
  useEffect(() => { 
    getGroupSelectorDatafromAPI();
  }, [])

  const submitHandle = (values) => {
    if(fromMode === "create"){
      const selectedUsers = values.group_members.map(item => item.id)
      // setSelectedUsers(selectedUsers)
      let CPData ={
        group_name:values.groupName,
        percentage:parseFloat(Number(values.percentage).toFixed(2)),
        group_members:selectedUsers,
      }
      ChannelGroupService.postCPgroupsData(CPData).then(res =>{
        if(res){    
          if (res.status === 201) {
            getGroupSelectorDatafromAPI();
            setSeverity("success");
            setSnackbarTitle( `${values.groupName} Group Created Successfully`);
            setOpen(true);
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
          }

        }
      }).catch(error => console.log(error))
      setShow(false);
    } else if (fromMode === "edit"){
      values.group_members.map(item => groupMembers.push( item.id))
      let EditCPData ={
        group_name:rowData.group_name,
        percentage:parseFloat(Number(rowData.percentage).toFixed(2)),
        group_members:groupMembers,
        id:rowData.id
      }
      ChannelGroupService.updateCPgroupData(EditCPData).then(res =>{
        if(res){  
          if(res.status === 200){
            getGroupSelectorDatafromAPI();
            // getgroupDataById(rowID)
            setSeverity("success");
            setSnackbarTitle("External RM added Successfully");   
            setOpen(true);
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true)
            }
  
          }
        }
      }).catch(error=>console.log(error))
      setShow(false);
      // setOpen(true);
    }
    else if(fromMode === "EditNamePercentage"){
      let editData = {
        group_name:values.groupName,
        percentage:parseFloat(Number(values.percentage).toFixed(2)),
        id:groupId
      };
      ChannelGroupService.updateExternalRMgroup(editData).then(res => {
        if(res){
          if(res.status === 200){
            getGroupSelectorDatafromAPI();
            setSeverity("success");
            setSnackbarTitle(" Updated Successfully");
            setShow(false);
            setOpen(true);
          }else{
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setShow(false)
              setOpenSessionExpiredModal(true)
            }
          }
        }
        
      })
    }
  };

  const handleDeleteTableRow= () =>{
    setSpinner(true)
    ChannelGroupService.deleteCPgroupsByID(deleteId).then(res =>{
      if(res){
        if(res.status === 200){
          getGroupSelectorDatafromAPI();
          setSeverity("success");
          setSnackbarTitle("External RM Group Deleted Successfully");
          setOpen(true);
          setDeleteOpen(false)
          setSpinner(false)
        }else{
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setDeleteOpen(false)
            setSpinner(false)
            setOpenSessionExpiredModal(true)
          }
        }
      }
    }).catch(error => {
      setDeleteOpen(false)
      setSpinner(false)
    })
  }

  const getMuiTheme = GetMuiTheme

  const groupColumn = [
    {
      name: "",
      label: "Sl. No.",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const slno = tableMeta.rowIndex;
          return (
            <div className={classes.tableEllips}>
              <Typography
                variant="body2"
              >
                {slno + 1}
              </Typography>
            </div>
          );
        },
      }
    },
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false
      }
    },
    {
      name: "group_name",
      label: "Group Name",
      options: {
        hint:"User Created Commission Group",
        filter: false,
        sort: false,
      }
    },
    {
      name: "percentage",
      label: "Percentage",
      options: {
        hint:"Commission Defined to Display as Price per Share",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (             
              <Typography
                  variant='body2'
                  noWrap
              >{value}%</Typography>
          )
      }
      }
    },
    {
      name: "group_member_count",
      label: "Channel Partner",
      options: {
        hint:"Number of Members Under the Commission Group",
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div className={classes.tableEllips}>
              <Link
              style={{cursor:'pointer'}}
                onClick={() => {
                  setGroupId(tableMeta.rowData[1])
                  setFormMode("edit")
                  setRowID(tableMeta.rowData[1])
                  handleShow("channelPartnerList",tableMeta.rowData[1] ) 
                }}
              >
                {value}
              </Link>
            </div>
          );
        },
      }
    },

    {
      name: "last_updated",
      label: "Last Updated",
      options: {
        filter: false,
        sort: true,
        display:false,
        customBodyRender: (value, tableMeta, updateValue) => {
          let valueMoment= moment(value).format("DD-MM-YYYY LTS")
          return (             
              <Typography
                  variant='body2'
                  noWrap
              >{valueMoment}</Typography>
          )
      }
      },
    },
    {
      name: "",
      label: "Action",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span className={classes.editdeletbtn} >
              <IconButton className={classes.iconbtn} aria-label="edit" 
                onClick={() => {handleShow("edit",tableMeta.rowData[1]); setRowID(tableMeta.rowData[1]);
                setGroupId(tableMeta.rowData[1])}}
              >
                <EditIcon />
              </IconButton>
              <IconButton className={classes.iconbtn} aria-label="delete" 
                onClick={() => {setDeleteOpen(true); setDeleteId(tableMeta.rowData[1])
                }}>
                <DeleteIcon className={classes.deletebtn}/>
              </IconButton>
            </span>
          );
        }
      }
    }
  ]

  return (
    <Root>
      <Page title="Unlistedkart | Create Group">
        <NormalCard
          title={"External RM Group"}
          // subtitle={"List of Channel Partners and Clients"}
          btn={
            <Button
              color="primary"
              className={classes.popDownBtn}
              size="medium"
              onClick={() => handleShow("add")}
              variant="contained"
            >
              Add
            </Button>
          }
        >
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
                data={groupData}
                columns={groupColumn}
                options={{
                  search: false,
                  print: false,
                  pagination: false,
                  download: true,
                  viewColumns: false,
                  filter: false,
                  rowsPerPage: 5,
                  rowsPerPageOptions: [25, 50, 75, 100],
                  selectableRows: "none",
                  sortOrder: {
                    name: 'last_updated',
                    direction: 'desc'
                  },
                  textLabels: {
                    body: {
                      noMatch: loading ?
                        'Loading...' : 'Sorry, there is no matching data to display'
                    },
                  },

                }}
              />
            </ThemeProvider>
        </NormalCard>
      </Page>
      <>
        {name === "edit" || name === "add" ? (
          <ModalCentered
            title={titleModal}
            open={show}
            toggle={handleShowClose}
            children={<GroupSelectorCreateForm rowID={rowID} isDropdown={isDropdown}  formSubmit={submitHandle} />}
          />
        ) : (
          name === "channelPartnerList" && 
          <ModalCentered
            title={titleModal}
            style={{ overflow: "hidden" }}
            open={show}
            toggle={handleShowClose}
            children={
              <ChannelPartnersListModel
                id={groupId}
                formSubmit={submitHandle} 
                style={{ padding: 0 }} 
                reLoadApi={()=>getGroupSelectorDatafromAPI()}
              />
            }
          />
        )}

        {
          name === "create" && 
           <ModalCentered
           title={titleModal}
           open={show}
           toggle={handleShowClose}
           children={<GroupSelectorCreateForm rowID={groupId}  isDropdown={isDropdown}  formSubmit={submitHandle} />}
         />
        }
        <AlertModal
              title="Are you sure you want to delete this?"
              open={deleteOpen}
              setOpen={setDeleteOpen}
              toggle={()=>setDeleteOpen(false)}
              onConfirm={()=>handleDeleteTableRow()}
              openLoading={spinner}
      
          >
        </AlertModal>
        <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        <SnackBar
          open={open}
          severity={severity}
          close={handleClose}
          snackbarTitle={snackbarTitle}
        />
      </>
    </Root>
  );
}
