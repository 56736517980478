import React from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import loadingSpinner from 'src/images/loading-spinner.gif'
import {
  FormControl,
  FormHelperText,
  Select,
  TextField,
  Container,
  InputLabel,
  MenuItem,

} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";



const PREFIX = 'SendDealtermEmail';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));


function SendDealtermEmail({data,formSubmit}) {

  const [loading, setLoading] = React.useState(false)



  return (
    <StyledContainer>
      <Formik
        initialValues={{
          emailTo:data.email ? 'client' : '',
          customEmail:''
        }}
        validationSchema={Yup.object().shape({
            customEmail: Yup.string().when("emailTo", {
                is:"custom",
                then: Yup.string()
                  .nullable()
                  .email("Enter a valid email")
                  .required("Required"),
                otherwise: Yup.string(),
              }),
        })}
        onSubmit={(values) => {
          setLoading(true) 
        const payload={
            user_name:data.user_name,
            toMail:values.emailTo ==='client' ?data.email: values.emailTo ==='channelPartner' ? data.channel_partner_email : values.customEmail,
            orderId:data.id,
            userId:data?.userId
        }
          formSubmit(payload)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Send email to*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="role"
                  className={classes.inputMargin}
                  label="Send email to*"
                  // select
                  // SelectProps={{ native: true }}
                  variant="outlined"
                  // error={Boolean(touched.emailTo && errors.emailTo)}
                  // helperText={touched.emailTo && errors.emailTo}
                  name="emailTo"
                  value={values.emailTo}
                  aria-describedby="outlined-weight-helper-text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                >
                { data.email &&  <MenuItem value="client">{data.email}</MenuItem>}
                  {data?.transaction_type_code === "INTERMEDIATE" && <MenuItem value="channelPartner">{data?.channel_partner_email}</MenuItem>}
                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
                <FormHelperText style={{ color: "#F44336", position: "absolute", top: 53 }}>
                  {errors.emailTo && touched.emailTo && errors.emailTo}
                </FormHelperText>
              </FormControl>
                   {values.emailTo ==='custom' ? <TextField
                     error={Boolean(touched.customEmail && errors.customEmail)}
                     helperText={touched.customEmail && errors.customEmail}
                     name="customEmail"
                     label="Email Id*"
                     fullWidth
                     InputLabelProps={{
                        shrink: true,
                        }}
                     onChange={handleChange}
                     value={values.customEmail}
                     className={classes.inputMargin}
                     variant="outlined"
                     /> : null}

              <Button
                color="primary"
                disabled={isSubmitting || values.emailTo ===""}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false ? "Send" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress} /> Send </>}
              </Button>
            </Container>
          </form>
        )}
      </Formik>

    </StyledContainer>
  );
}

export default SendDealtermEmail;
