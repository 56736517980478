import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import {  ThemeProvider } from "@mui/material/styles";
import NormalCard from "src/components/Cards/NormalCard";
import {
  Typography,
  IconButton,
  Button,
  Tooltip,
  Grid,
  CircularProgress,
} from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Chip from "@mui/material/Chip";
import { userService } from "src/_services/users";
import DeleteIcon from "@mui/icons-material/Delete";
import MUIDataTable from "mui-datatables";
import ModalCentered from "src/components/Modals/ModalCentered";
import DematCreateForm from "src/containers/Users/UserAccountDocument/DematCreateForm";
import AlertModal from "src/components/AlertModal/AlertModal";
import SnackBar from "src/components/SnackBar/SnackBar";
import ImageIcon from "@mui/icons-material/Image";
// import DescriptionIcon from '@mui/icons-material/Description';
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import EditIcon from '@mui/icons-material/Edit';
import ImageRotate from './Rotate';
import { useNavigate,useLocation } from "react-router-dom";
import { getLookUpDataService } from "src/_services/lookUp";
import GetMuiTheme from "src/components/theme";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from "react-redux";
import compareJSON from 'src/components/comparision';

const PREFIX = 'DematAccountData';

const classes = {
  iconpdf: `${PREFIX}-iconpdf`,
  addButton: `${PREFIX}-addButton`,
  tooltip: `${PREFIX}-tooltip`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.iconpdf}`]: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },

  [`& .${classes.addButton}`]: {
    fontSize: "13px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
      padding: "4px",
    },
  },

  [`& .${classes.tooltip}`]: {
    cursor: "copy",
  }
}));

const theme = GetMuiTheme

const DematAccountData = (props) =>{
   

    const [dematData, setDematData] = useState([])
    const [loading , setLoading] = useState(false);
    const [spinner , setSpinner] = useState(false);
    // const [disable, setDisable] =  useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [severity, setSeverity] = useState("");
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [accountID,setAccountID] = useState()
    const [loadSpinner, setLoadSpinner] = useState(false)
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)
    const [hover, setHover] = useState(false);
    const [url , setUrl] = React.useState(null);
    const [data,setData]=  useState(false)
    const [isPdf, setIsPdf] = useState(false)
    const location = useLocation();
    const navigate = useNavigate()
    const [show,setShow] = useState(false)
    const stateValue = useSelector(state => state?.authReducer?.auth);
    const role  = stateValue?.user?.default_role_code
    const defaultStatus = {
      dematId:false,
      status:false
    }
    const [beneficiary , setBeneficiary] = React.useState(defaultStatus);
    const onHover = (value) => {
      setHover(true);
      navigator.clipboard.writeText(value);

    };
  
    const onLeave = () => {
      setHover(false);
    };
const copy =((value)=>{
    return (
      <div
        onClick={() => {
          onHover(value);
        }}
        onMouseLeave={onLeave}
        style={{ display: "flex" }}
      >
        <Tooltip
          className={classes.tooltip}
          placement="right"
          title={hover ? "Copied" : "Click to Copy"}
        >
          <Typography
            variant="body2"
            style={{ textTransform: "capitalize" }}
            noWrap
          >
            {value}
          </Typography>
        </Tooltip>
      </div>
    )
  })

  const handleShowClose = () => {
    // handleShowClose
    // setRotation(0)
    navigate(`/app/user-details/${props.userID}`)
    setShow(false)
}
    const dematAccountDetailsHeading = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false
            }
        },
        {
            name: "dp_with",
            label: "DP With",
            options: {
                hint:'Depository Service company that your account is with (Eg. Zerodha, Angel Broking)',
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => copy(value, tableMeta),

                  }
        },
        {
            name: "dp_id",
            label: "DP ID",
            options: {
                hint:'Depository Participant Identification number assigned to the Depository company',
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => copy(value, tableMeta),
            }
        },
        {
            name: "client_id",
            label: "Client ID",
            options: {
                hint:'Client ID - Client ID under the Depository account',
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => copy(value, tableMeta),
            }
        },
        {
            name: "fileid",
            label: "CML Copy",
            options: {
                hint:'Client Master List that has information of the Demat account with the Broker',
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (                      
                        value !== null &&  <Typography
                            style={{ maxWidth: 300,cursor:'pointer' }}
                            variant='body2'
                            // component={isPdf ? "a" : null}
                            // noWrap={false}
                            // // href= {isPdf ? `${value}` : null}
                            // color="primary"
                            // Download
                            // target= {isPdf ? "_blank" : null}
                            // onClick={()=>{  
                            //     // if(!isPdf){
                            //         setIsPdf(isPdf)
                            //         setShow(true)  
                            //         setTitleModal("Demat Proof")
                            //         setUrl(tableMeta.rowData)
                            //     // }
                            // }}
                            component={"a"}
                            href={ `/app/user-details/${props.userID}?dematId=${value}`}
                            target="_blank"
                        >
                      
                            {(tableMeta.rowData[5] === "image/jpg" || tableMeta.rowData[5] === "image/png" || tableMeta.rowData[5] === "image/gif" ||  tableMeta.rowData[5] === "image/jpeg") && (<ImageIcon className={classes.iconpdf} size='small' />)}
                            {tableMeta.rowData[5] === "application/pdf" && (<PictureAsPdfIcon className={classes.iconpdf} size='small' />)}
                            {/* {tableMeta.rowData[5] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (<DescriptionIcon className={classes.iconpdf} size='small' />)} */}
                            {'View'}
                        </Typography>
                    )
                }
            }
        },
        // mime_type
        {
            name: "mime_type",
            label: "mime_type",
            options: {
                filter: false,
                sort: false,
                display: false,
            
            }
        },
        {
          name: "is_beneficiary_mapped_demat",
          label: "Beneficiary Status",
          options: {
              filter: false,
              sort: true,  
              customBodyRender: (value, tableMeta) => {
                return (
                  <div style={{cursor:'pointer'}} 
                  onClick={()=>{
                   (role=== 'ADMIN' || role ==='OPERATION')  && handleBeneficiaryStatus(value,tableMeta.rowData[0])
                  }}>
                 { value? 
                  <div style={{alignItems:'center', display:'flex', gap:'4px'}}>
                    <CheckCircleOutlineIcon style={{color:'green'}}/> Added
                    </div>:  <div style={{alignItems:'center', display:'flex', gap:'4px'}}><CancelIcon color={'error'}/>Not added</div>}
                    </div>
                )         
          }
        }
      },
        {
            name: "is_primary",
            label: "Default",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return value === true ? (                       
                      <Chip size="small" label={"Primary"} color="primary"/>)
                      : (
                        <div style={{display:"flex", alignItems:"center"}}
                        onClick={async ()=>{
                            await setLoading(true)
                            makePrimaryDemat(tableMeta.rowData[0])
                        }}>
                            <Chip 
                                size="small" 
                                label="Make Primary"
                              
                                style={{marginRight:10, cursor:'pointer'}}
                                
                            />
                        </div>) 
                }
            }
        },
        {
            name: "is_primary",
            label: "Action",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return  (
                        <span className={classes.editdeletbtn} >
                             <IconButton
                                edge="end"
                                // disabled={value}
                                aria-label="delete"
                                onClick={() => handleEdit(tableMeta.rowIndex)}
                            >
                                <EditIcon />
                            </IconButton>
                            <IconButton disabled={value} className={classes.iconbtn} onClick={() => handleConfirm(tableMeta.rowData[0])}>
                                <DeleteIcon  />
                            </IconButton>

                        </span>
                    ) 
                }
            }
        }
    ]

    const handleEdit=((id)=>{
        setData({
            dpWith: dematData[id].dp_with,
            dpId: dematData[id].dp_id,
            clientId: dematData[id].client_id,
            cmlCopy: '',
            primaryAccount: dematData[id].is_primary,
            id: dematData[id].id
        })
            setTitleModal("Edit Demat Account");
            setSeverity("success");
            setSnackbarTitle("Demat account updated successfully");
            setOpenModal(true);
            
    })

    const handleBeneficiaryStatus =((status,id)=>{
      setBeneficiary({
        dematId:id,
        status:status
      })
    })

    const handleBeneficiary =(()=>{
      const filteredData = dematData.filter((item)=> item.id !== beneficiary.dematId) 
      const status = !beneficiary.status ? true :dematData.length>1 ? filteredData.some((item)=>item.is_beneficiary_mapped_demat === true) : !beneficiary.status
      const data={
        userID
        :props.userID,
        beneficiaryStatus:status,
        demat:[{
          dematId:beneficiary.dematId,
          status:!beneficiary.status
        }]
      }

     userService.updateBeneficiaryStatus(data).then((res) => {
      if (res.status === 200 && res.data.message ==='Successfully updated') {
          setBeneficiary(defaultStatus)
          setOpenSnackBar(true);
          getDematAccounts()
          setSeverity("success");
          setSnackbarTitle("Successfully updated beneficiary status");
      } else if (
          res.status === 401 &&
          res.data.name === "TokenExpiredError"
      ) {
          setOpenSessionExpiredModal(true);
      } else {
        setBeneficiary(defaultStatus)
        setSeverity("error");
          setSnackbarTitle("Something went wrong !");
          setOpenSnackBar(true);
      }
    })
    })
  const editDematAccount = async (values) => {
    setSpinner(true);
    const formData1 = new FormData();
    setSpinner(true);
    const  result = compareJSON(values,data)

    formData1.append("Doc", values.cmlCopy);

    let password =
      values.cmlCopy.type === "application/pdf"
        ? await userService.checkIsDematPasswordProtected(formData1)
        : false;

    if (password?.data?.passwordProtected) {
      setSpinner(false);
      setSeverity("Error");
      setOpenSnackBar(true);
      setSnackbarTitle("Password protected document is not allowed");
    } else {
      const formData = new FormData();
      formData.append("dp_with", values.dpWith);
      formData.append("dp_id", values.dpId);
      formData.append("client_id", values.clientId);
      formData.append("is_primary", values.primaryAccount);
      formData.append("cml_file", values.cmlCopy);
      formData.append("user_id", props.userID);
      formData.append('updatedValues', JSON.stringify(result)) 

      userService.updateDemat(values.id, formData).then((res) => {
        if (res.status === 200) {
          getDematAccounts();
          setOpenModal(false);
          setOpenSnackBar(true);
          setSpinner(false);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenModal(false);
            setOpenSessionExpiredModal(true);
          }
        }
      });
    }
  };

  const makePrimaryDemat = (id) => {
    userService.makePrimaryDemat(props.userID, id).then((res) => {
      if (res) {
        if (res.status === 200) {
          getDematAccounts();
          setSeverity("success");
          setSnackbarTitle("Updated");
          setOpenSnackBar(true);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (props.userID !== null) {
      getDematAccounts();
    }
  }, [props.userID]);

    const getDematAccounts = () => {
        userService.getUserDemat(props.userID)
        .then(res => {
            if (res) {
               
                
                if (res.status === 200) {
                    setDematData(res.data)
                }else{
                    if (res.status === 401 && res.data.name === "TokenExpiredError") {
                        setOpenSessionExpiredModal(true)
                    }
                }
            }

        })
    }

    useEffect(() => {
      const isFile = location.search.split('dematId=')
      if (isFile.length > 1 && dematData) {
        const data= dematData.filter((item)=>  item.fileid === Number(isFile[1]))
         setShow(true);
         handleFileUrl(isFile[1],data[0]);
         setTitleModal("Demat Proof");
       }   
    }, [dematData])

    const handleFileUrl = (id,value) => {
      getLookUpDataService.getObjectUrl(id).then((res) => {
        if (res.data.success) {
        setIsPdf(res.data.mimetype  ==="application/pdf" ?true : false)
          setUrl({
            dpWith: value?.dp_with,
            dpId: value?.dp_id,
            clientId: value?.client_id,
            url: res.data.data,
          });
        }
      });
    };
  

  const handleConfirm = (accId) => {
    setDeleteConfirm(true);
    setAccountID(accId);
  };

  const handleDeleteTableRow = () => {
    setLoadSpinner(true);
    setDeleteConfirm(false);
    setLoadSpinner(false);

    userService.deleteUserDemat(accountID).then((res) => {
      if (res) {
        if (res.status === 200) {
          getDematAccounts();
          setSeverity("success");
          setSnackbarTitle("Demat Account Deleted Successfully");
          setOpenSnackBar(true);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
            setLoadSpinner(false);
          }
        }
      }
    });
  };

  const openModalHandler = (type, add) => {
    setData();
    setTitleModal("Add Demat Account");
    setSeverity("success");
    setSnackbarTitle("Demat Account Added Successfully");
    setOpenModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const submitHandle = async (values) => {
    const formData1 = new FormData();
    setSpinner(true);

    formData1.append("Doc", values.cmlCopy);

    let password =
      values.cmlCopy.type === "application/pdf"
        ? await userService.checkIsDematPasswordProtected(formData1)
        : false;

    if (password?.data?.passwordProtected) {
      setSpinner(false);
      setSeverity("Error");
      setOpenSnackBar(true);
      setSnackbarTitle("Password protected document is not allowed");
    } else {
      const formData = new FormData();
      formData.append("dp_with", values.dpWith);
      formData.append("dp_id", values.dpId);
      formData.append("client_id", values.clientId);
      formData.append("is_primary", values.primaryAccount);
      formData.append("cml_file", values.cmlCopy);

      userService.createDemat(props.userID, formData).then((res) => {
        if (res.status === 201) {
          getDematAccounts();
          setOpenModal(false);
          setOpenSnackBar(true);
          setSpinner(false);
        }
      });
    }
  };

  return (
    <Root>
      <NormalCard
        title="Demat Account Details"
        className={classes.cardroot}
        btn={
     
            <Button
              color="primary"
              onClick={() => openModalHandler("demat", "create")}
              size="medium"
              type="submit"
              variant="outlined"
              name="addDemat"
              className={classes.addButton}
              value="addDemat"
              disabled={props.userID !== null ? false : true}
            >
              Add Account
            </Button>
        }
      >
        <ThemeProvider theme={theme}>
          <MUIDataTable
            data={dematData}
            columns={dematAccountDetailsHeading}
            options={{
              download: true,
              viewColumns: false,
              filter: false,
              pagination: false,
              search: false,
              print: false,
              rowsPerPage: 5,
              rowsPerPageOptions: [5, 10, 25],
              selectableRows: "none",
              // display: props.display,
              textLabels: {
                body: {
                  noMatch: loading
                    ? "Loading..."
                    : "Sorry, there are no matching data to display",
                },
              },
            }}
          />
        </ThemeProvider>
      </NormalCard>

      <ModalCentered
        title={titleModal}
        open={openModal}
        toggle={closeModalHandler}
        children={
          <DematCreateForm
            data={data}
            isPrimary={dematData.length === 0 ? true : false}
            loading={spinner}
            setLoading={setSpinner}
            spinner={spinner}
            formSubmit={data ? editDematAccount : submitHandle}
          />
        }
      />

      <AlertModal
        title="Are you sure you want to delete this account?"
        open={deleteConfirm}
        toggle={() => setDeleteConfirm(false)}
        onConfirm={handleDeleteTableRow}
        openLoading={loadSpinner}
      ></AlertModal>
      <ModalCentered
        title={titleModal}
        fullScreen={true}
        open={show}
        toggle={handleShowClose}
        children={url?.url ?
      (  <div style={{textAlign:'center', position:'relative'}}>
        <Grid container style={{display:'flex'}}>
            <Grid item xs={12} md={12} style={{justifyContent:'center', display:'flex'}}>
            <div style={{textAlign:'start'}}>
                <Typography><b>DP With:</b> {url.dpWith}</Typography>
                <Typography><b>DP Id:</b> {url.dpId}</Typography>
                <Typography><b>Client Id:</b> {url.clientId}</Typography>
                </div>
            </Grid>
            <Grid item xs={12} md={12}>
     <div style={{ 
            alignItems:'center', display:'flex', justifyContent:'center'}}>
{                isPdf? <iframe title="demat" style={{width:'100%', height:'calc(100vh - 100px)'}} src={url.url}/>:
    <ImageRotate
  alt="cool image"
  type="users"
  image={url &&url.url}/>}
     </div>
           </Grid>
        </Grid>
        <br/>
       {/* <div>
       <Button 
        onClick={()=>{
            if(rotation==270){
                setRotation(0)
            }else{
                setRotation(rotation+90
            }
        }}
        color="primary"
        variant='contained'
        style={{marginTop:'2rem', textTransform:'none'}}
                startIcon={<RotateRight />}

        >Rotate</Button>
         <Button 
        onClick={()=>{
            handleShowClose()
        }}
        variant='outlined'
        style={{marginTop:'2rem', marginLeft:'1rem' ,textTransform:'none'}}

        >Close</Button>
       </div> */}
        </div>) :(
          <Typography> <CircularProgress size={20} /> Loading...</Typography>
        )}
      />
      <AlertModal
        title={`Are you sure you want to map this  demat as ${beneficiary.status ? 'not a beneficiary' : 'a beneficiary'} account?`}
        open={beneficiary.dematId}
        toggle={() => setBeneficiary(defaultStatus)}
        onConfirm={() => handleBeneficiary()}
        openLoading={spinner}
      ></AlertModal>
      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />

      <SessionExpiredModal open={openSessionExpiredModal} />
    </Root>
  );
};

export default DematAccountData;
