import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { Button, Typography, Grid,Chip,Avatar,  AppBar,Box, Tabs, Tab,Tooltip } from "@mui/material";
import {   ThemeProvider, useTheme } from "@mui/material/styles";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Page from "src/components/Page";
import NormalCard from "src/components/Cards/NormalCard";
import { securityService } from "src/_services/security";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
// import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
// import ReactHtmlParser from "react-html-parser";
import moment from 'moment'
import ServiceCard from "src/components/Cards/ServiceCard";
import SwipeableViews from "react-swipeable-views-react-18-fix";
import PropTypes from "prop-types";
import SecurityNews from "./SecurityNews";
// import PriceTrend from "./PriceTrend";
import Scriplogs from './Scriplogs';
import GetMuiTheme from "src/components/theme";
import { useSelector } from "react-redux";
const PREFIX = 'SecurityDetails';

const classes = {
  root: `${PREFIX}-root`,
  hdrlblbtn: `${PREFIX}-hdrlblbtn`,
  addButton: `${PREFIX}-addButton`,
  tooltip: `${PREFIX}-tooltip`,
  card: `${PREFIX}-card`,
  bottomborder: `${PREFIX}-bottomborder`,
  editbutton: `${PREFIX}-editbutton`,
  induvidual: `${PREFIX}-induvidual`,
  labelname: `${PREFIX}-labelname`,
  details: `${PREFIX}-details`,
  formgrgroup: `${PREFIX}-formgrgroup`,
  removeLink: `${PREFIX}-removeLink`,
  table: `${PREFIX}-table`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.root}`]: {},

  [`& .${classes.hdrlblbtn}`]: {
    display: "flex",
    justifyContent: "end",
    marginTop: theme.spacing(0),
    alignItems: "center",
    marginBottom: "1%",
  },

  [`& .${classes.addButton}`]: {
    textAlign: 'end',
    marginLeft:"10px"
},

  [`& .${classes.tooltip}`]: {
    cursor:'copy'
  },

  [`& .${classes.card}`]: {
    borderBottom: "none",
    padding: 0,
  },

  [`& .${classes.bottomborder}`]: {
    backgroundColor: "#fff",
    borderBottom: "1px solid #e8e8e8",
  },

  [`& .${classes.editbutton}`]: {
    borderRadius: "14px 14px 0 0",
    boxShadow: "none",
    textTransform: "capitalize",
    marginLeft:'1rem',
  },

  [`& .${classes.induvidual}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.labelname}`]: {
    [theme.breakpoints.up(576)]: {
      width: "22%",
    },
    width: "32%",
    textAlign: "end",
    fontWeight: "500",
  },

  [`& .${classes.details}`]: {
    // width: "68%",
    marginLeft: theme.spacing(2),
  },

  [`& .${classes.formgrgroup}`]: {
    display: "flex",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.removeLink}`]: {
    cursor: "pointer",
  },

  [`& .${classes.table}`]: {
    padding: "3rem",

    color: "rgba(0, 0, 0, 0.87)",
    transition: " box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "& table,th,td": {
      borderBottom: "1px solid #ddd",
    },
    "& th": {
      color: "#ffffff",
      padding: "6px",
      fontWeight: 500,
      lineHeight: "1rem",
      whiteSpace: "nowrap",
      backgroundColor: "#6C6B74",
    },
    "& table": {
      width: "100%",
      textAlign: "left",
      borderCollapse: "collapse",

      borderSpacing: 0,
    },
    "& th,td": {
      padding: 8,
      whiteSpace: "nowrap",
    },
    // '& tr:nth-child(even)':{
    //     backgroundColor: "#f2f2f2"
    // }
    // "& tr:hover": { backgroundColor: "#f5f5f5" },
    // "& tr:nth-child(even)": { backgroundColor: "#f2f2f2" },
    // "& tr:nth-child(odd)": { backgroundColor: "#F2FAFA" },
  }
}));


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const SecurityDetails = (props) => {

  let id = useParams();
  let useParamsId = props?.id || id.id;
  const theme = useTheme();
  const location = useLocation();
  const tabvalue = location && location.state && location.state.tabvalue ? location.state.tabvalue : 0
  const navigate = useNavigate();
  const [securityDetails, setSecurityDetails] = useState([]);
  // const [overviewFile, setOverviewFile] = useState("");
  // const [fullReportFile, setFullReportFile] = useState("");
  // const [feeType, setFeeType] = useState("");
  // const [reportPrice, setReportPrice] = useState("");
  const [companyOverview, setCompanyOverview] = useState("");
  const [activeTab, setActiveTab] = React.useState(tabvalue);
  const role = useSelector((auth)=>auth.authReducer.auth.user.default_role_code)
  // const [securityDetailsId, setSecurityDetailsId] = useState( {
  //     addressLineOne: "",
  //     addressLineTwo: "",
  //     city: "",
  //     states: "",
  //     country: "",
  //     postalCode: "",
  //     scripName: "",
  //     companyName: "",
  //     sector: "",
  //     isin:"",
  //     pan: "",
  //     cin: "",
  //     gstin: "",
  //     email: "",
  //     officeNo: "",
  //     faceValue: "",
  //   });

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    // setPage(0);
    // setbtnSwitch(!btnSwitch);
  };

  const handleChangeIndex = (index) => {
    setActiveTab(index);
  };
  const getMuiTheme =  GetMuiTheme


  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  const handleEditBtn = () => {
    navigate("/app/security-create?mode=edit", {
      replace: true,
      state: { userID: useParamsId },
    });
  };

  // const handleNewsBtn = () => {
  //   navigate(`/app/security/news/${useParamsId}`, {
  //     replace: true,
  //     state: { userID: useParamsId },
  //   });
  // };

  const createMarkup = (htmlString) => {
    return { __html: htmlString };
};


  const getDetailsByID = () => {
    securityService.getSecurityByID(useParamsId).then((res) => {
      if (res) {
        if (res.status === 200) {
          if (res.data[0]) {
            setSecurityDetails(res.data[0]);
            // setOverviewFile(
            //   res.data[0].overview_report[0].overview_report_file_url
            // );
            // setFullReportFile(res.data[0].full_report[0].full_report_file_url);
            // setFeeType(res.data[0].full_report[0].fee_type);
            // setReportPrice(res.data[0].full_report[0].report_price);
            if (res.data[0].company_overview === null) {
              setCompanyOverview("");
            } else {
              const removedTag = res.data[0].company_overview
                .replace(
                  '<figure className="table">',
                  '<div style="overflow-x:auto;">'
                )
                .replace("</figure>", "</div>");
              setCompanyOverview(removedTag);
            }
          }
        }else if(
                      res.status === 401 &&
                      res.data.name === "TokenExpiredError"
                  ) {
          setOpenSessionExpiredModal(true)
        }
      }
    });
  };

  useEffect(() => {
    getDetailsByID();
  }, []);

  // const removeLogo = (id) => {
  //     securityService.deleteSecurityLogo(id).then(res => {
  //         if(res){
  //             if(res.status === 200 ){
  //                 getDetailsByID()
  //             }
  //         }

  //     })

  // }

  const [hover, setHover] = useState(false);

  const onHover = (value) => {
    setHover(true);
    navigator.clipboard.writeText(value);
  };
const InvestorDetails = securityDetails.keyinvestordata ? JSON.parse(securityDetails.keyinvestordata) : []
const FinancialDetails = securityDetails.financialdata ? JSON.parse(securityDetails.financialdata) : []
  const onLeave = () => {
    setHover(false);
  };

  const CopyText=((value)=>{
    return(
      <Tooltip placement="right" className={classes.tooltip} title={hover ? "Copied" :"Click to Copy"}>
      <p
             onClick={()=>{
              onHover(value)
            }}
            onMouseLeave={onLeave} className={classes.details}>{value}</p>
            </Tooltip>
    )
  })
  return (
    <Root>
    <Page title="Unlistedkart | Security Details">
         {
                  tabvalue === 2 && 
                  <Button
                  style={{marginBottom:'1rem'}}
        variant="contained"
        color="primary"
        onClick={()=>[
          navigate("/app/security-news")  
         ]}
        className={classes.button}
        startIcon={<ArrowBackIcon />}
      >
        Back
      </Button>
                 }
         <ServiceCard className={classes.card}>
            <ThemeProvider theme={getMuiTheme}>
              <div style={{ position: "relative" }}>
                <div className={classes.bottomborder}>
                  <AppBar position="static">
                    <Tabs
                      value={activeTab}
                      onChange={handleChange}
                      style={{
                        // marginBottom: 24,
                        borderBottom: "1px solid #e8e8e8",
                      }}
                    >
                      <Tab label="Details" {...a11yProps(0)} />
                      <Tab label="About" {...a11yProps(1)} />
                      <Tab label="News" {...a11yProps(2)} />
                      {/* <Tab label="Price Trend" {...a11yProps(3)} /> */}
                      <Tab label="Scrip logs" {...a11yProps(3)}/>
                    </Tabs>
                  </AppBar>
                </div>
              </div>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeTab}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={activeTab} index={0}>
      <NormalCard title="Security Details"
        btn={
          <div style={{ display: "flex" }}>
              {/* <Button   className={classes.addButton}
                        variant="contained"
                        color="primary"
                        size='medium'
                        onClick={handleNewsBtn}>
                       News
                </Button> */}
             { role ==='ADMIN_VIEWER' ? null :  <Button   className={classes.addButton}
                        variant="contained"
                        color="primary"
                        size='medium'
                        onClick={handleEditBtn}>
                       Edit
                </Button>}
            </div>
            }>
        <div className={classes.induvidual}>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Scrip Name</label>
            {CopyText(securityDetails.name)}
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Sector</label>
            {CopyText(securityDetails.sector)}
          </div>
          
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Trading Status</label>
            <p className={classes.details}> {securityDetails.hide_trading_status ? "Hidden" : "Visible" }</p>
          </div>
         { securityDetails.hide_trading_status && <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Hidden Reason</label>
            {CopyText(securityDetails.hiding_reason)}
          </div>}
        </div>
      </NormalCard>

      {/* <NormalCard title="Company Info." >
                <div className={classes.induvidual}>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Company Name</label>
                        <p className={classes.details}>{securityDetails.parent_company_name}</p>
                    </div>

                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Face Value</label>
                        <p className={classes.details}>{securityDetails.face_value}</p>
                    </div>
                    <div className={classes.formgrgroup} >
                        <label className={classes.labelname}>Sector</label>
                        <p className={classes.details}>	{securityDetails.sector}</p>
                    </div>


                </div>
            </NormalCard> */}

      <NormalCard title="Company Info.">
        <div className={classes.induvidual}>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Company Name</label>
            {CopyText(securityDetails.parent_company_name)}
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname} >ISIN</label>
            {CopyText(securityDetails?.isin)}
          </div>
          {securityDetails?.related_security_tag && (
                <div className={classes.formgrgroup}>
                    <label className={classes.labelname}>Tags</label>
                    <p className={classes.details}>
            {securityDetails?.related_security_tag.map((value)=>{
              return (
               <Chip
               key={value.tag_name}
               style={{marginRight:"4px"}}
               size="small" 
               avatar={<Avatar alt={value.tag_name} src={value.tag_icon_url}/>}
               className={classes.chipMargin}
               label={value.tag_name}
               variant="outlined"
           />
           )})}
           </p>
           </div>
         )}
           <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Market Capital</label>
            {CopyText(securityDetails.related_market_capital_id === 0 ? "Micro" : securityDetails.related_market_capital_id === 1 ? "Small"  : securityDetails.related_market_capital_id === 2 ? "Middle" : securityDetails.related_market_capital_id === 3 ? "Large" : "")}
         </div>
         {securityDetails?.status && (
           <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Status</label>
           <p className={classes.details}>
             {securityDetails?.status === 1 ? "Available" :  securityDetails?.status === 2 ? "Out of Stock" : securityDetails?.status === 3 ? "Coming soon"  : null}
           </p>
         </div>
         )}
         {(securityDetails?.available_cdsl || securityDetails?.available_nsdl)  && (
           <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Available on</label>
           <p className={classes.details}>
             {securityDetails?.available_cdsl && "CDSL"}  {securityDetails?.available_nsdl && "NSDL"}
           </p>
         </div>
         )}

          {securityDetails.logo_url && (
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>Logo</label>
              <div className={classes.details}>
                <img
                  src={securityDetails.logo_url}
                  width="80px"
                  alt="Company Logo"
                />
                {/* <Typography className={classes.removeLink}  color="primary" variant="body1" 
                                    onClick={() => removeLogo(securityId)}
                             >Remove</Typography> */}
              </div>
            </div>
          )}
        </div>
      </NormalCard>
      <NormalCard title="Other Details">
      <div className={classes.formgrgroup}  >
            <label className={classes.labelname}> &nbsp;&nbsp;Office No</label>
            {CopyText(securityDetails.office_no)}
          </div>
      <div  className={classes.formgrgroup} >
            <label className={classes.labelname}>Registration No</label>
            {CopyText(securityDetails.register_no)}
          </div>
          <div className={classes.formgrgroup} >
            <label className={classes.labelname}>Email ID</label>
            {CopyText(securityDetails.email)}
          </div>

          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>GSTIN</label>
            {CopyText(securityDetails.gstin)}
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>PAN</label>
            {CopyText(securityDetails.pan)}
          </div>
      </NormalCard>

   

      {/* <NormalCard title="Research Reports">
        <div className={classes.induvidual}>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Overview Report</label>

            <div
              style={{
                display: "flex",
                width: "68%",
                marginLeft: 16,
              }}
            >
              {overviewFile && (
                <>
                  <div>
                    <PictureAsPdfIcon color="primary" />
                  </div>
                  <div>
                    <Typography
                      style={{ maxWidth: 300, paddingLeft: 8 }}
                      component={"a"}
                      noWrap={false}
                      href={`${overviewFile}`}
                      color="primary"
                      target="_blank"
                    >
                      Overview Report
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Full Report</label>
            <div
              style={{
                display: "flex",
                width: "68%",
                marginLeft: 16,
              }}
            >
              {fullReportFile && (
                <>
                  <div>
                    <PictureAsPdfIcon color="primary" />
                  </div>
                  <div>
                    <Typography
                      style={{ maxWidth: 300, paddingLeft: 8 }}
                      component={"a"}
                      noWrap={false}
                      href={`${fullReportFile}`}
                      color="primary"
                      target="_blank"
                    >
                      Full Report
                    </Typography>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Payment</label>
            <p className={classes.details}>{feeType}</p>
          </div>
          {reportPrice !== null ? (
            <div className={classes.formgrgroup}>
              <label className={classes.labelname}>Price</label>
              <p className={classes.details}>{reportPrice}</p>
            </div>
          ) : (
            <></>
          )}
        </div>
      </NormalCard> */}

      {/* <NormalCard title="Address Details">
        <div className={classes.induvidual}>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Address One</label>
            <p className={classes.details}>{securityDetails.address1}</p>
          </div>

          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Address Two</label>
            <p className={classes.details}>{securityDetails.address2}</p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>City</label>
            <p className={classes.details}> {securityDetails.city}</p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>State</label>
            <p className={classes.details}> {securityDetails.state}</p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Postal Code</label>
            <p className={classes.details}> {securityDetails.zipcode}</p>
          </div>
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Country</label>
            <p className={classes.details}> {securityDetails.country}</p>
          </div>
        </div>
      </NormalCard> */}
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
      <NormalCard title="Investor Details">
        <div className={classes.induvidual}>
        {InvestorDetails && InvestorDetails.length>=1 ?(
          <div>
            <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Total  Investment</label>
           {CopyText(securityDetails?.totalinvestment)}
           </div>
              <div className={classes.formgrgroup} style={{borderBottom:'1px solid grey'}}>
           <label className={classes.labelname}>Investor Name </label>
           <p className={classes.details} style={{fontWeight: "500"}}>Percentage</p>
         </div>
                
           { InvestorDetails.map((item,index)=>{
             return(
           <div className={classes.formgrgroup} key={index}>
           <label className={classes.labelname} style={{fontWeight:400}}> {CopyText(item?.investorName)} </label>

           {CopyText(item?.investorPercentage)}

         </div>
                )
            })}
            </div>
        )
        : (
        <div style={{textAlign:"center"}}>
        Sorry, there are no matching data to display
        </div>
       )}

        </div>
      </NormalCard>
      <NormalCard title="Financial Details">
        <div className={classes.induvidual}>
        {FinancialDetails && FinancialDetails.length>=1 ?
      <div>
       {  FinancialDetails.map((item,index)=>{
        return(
           <div className={classes.formgrgroup} key={index}>
           <label className={classes.labelname}>{item.financialName}</label>
           {CopyText(`${item.financialValue} ${item.financialName === "P/E Ratio" ? "%" :item.financialName === "EPS" ? "": "Crores"}`)}
         </div>
          )
       })}
          <div className={classes.formgrgroup}>
            <label className={classes.labelname}>Face Value</label>
            <p className={classes.details}>{securityDetails.face_value}</p>
          </div>
        <div className={classes.formgrgroup}>
           <label className={classes.labelname}>No of outstanding shares Count</label>
           {CopyText(securityDetails.outstanding_shares_count)}
         </div>
         <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Market Capitalization</label>
           {CopyText(securityDetails.market_capitalization_value)}
         </div>
         <div className={classes.formgrgroup}>
           <label className={classes.labelname}>P/BV Ratio</label>
           {CopyText(securityDetails.pbv_ratio)}
         </div>
         <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Book Value</label>
           {CopyText(securityDetails.book_value)}
         </div>
         <div className={classes.formgrgroup}>
           <label className={classes.labelname}>ROFR Require</label>
           <p className={classes.details}>{securityDetails.rofr ? "Yes" : "No"}</p>
         </div>
         <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Last Traded Price</label>
           <p className={classes.details}>{securityDetails.last_traded_price}</p>
         </div>
         <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Lifetime High Price</label>
           <p className={classes.details}>{securityDetails.lifetime_high}</p>
         </div>
         <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Lifetime Low Price</label>
           <p className={classes.details}>{securityDetails.lifetime_low}</p>
         </div>
         <div className={classes.formgrgroup}>
           <label className={classes.labelname}>52 Weeks High Price</label>
           <p className={classes.details}>{securityDetails.week_52_high}</p>
         </div>
         <div className={classes.formgrgroup}>
           <label className={classes.labelname}>52 Weeks High Low</label>
           <p className={classes.details}>{securityDetails.week_52_low}</p>
         </div>
        <div className={classes.formgrgroup}>
           <label className={classes.labelname}>Report Date</label>
           <p className={classes.details}>{moment(securityDetails.financiallastupdatedate).format("DD-MM-YYYY")}</p>
         </div>
        </div>
         :(
          <div style={{textAlign:"center"}}>
           Sorry, there are no matching data to display
            </div>
                 )}
        </div>
      </NormalCard>
      <NormalCard title="Company Overview">
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridPadding}
          >
            {/* <div style={{ padding: "1rem" }}  dangerouslySetInnerHTML={{ __html: () }}> */}
            {/* <div className={classes.table}>
            {ReactHtmlParser(companyOverview)}
            </div> */}
                        <div  className={classes.table} dangerouslySetInnerHTML={createMarkup(companyOverview)} />
            {/* </div> */}
          </Grid>
        </Grid>
      </NormalCard>
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                <SecurityNews id={useParamsId} name={securityDetails.name} role={role}/>
                </TabPanel>
                {/* <TabPanel value={activeTab} index={3}>
          <PriceTrend />
                </TabPanel> */}
                <TabPanel  value={activeTab} index={3}>
              <Scriplogs id={useParamsId}/>
                </TabPanel>
      </SwipeableViews>
      </ThemeProvider>
      </ServiceCard>
      <SessionExpiredModal open={openSessionExpiredModal} />
    </Page>
    </Root>
  );
};

export default SecurityDetails;
