import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import NormalCard from "src/components/Cards/NormalCard";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

import { userDocuments } from "src/_services/userDocuments";
import ModalCentered from "src/components/Modals/ModalCentered";
import DocumentCreateForm from "src/containers/Users/UserAccountDocument/DocumentCreateForm";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import AlertModal from "src/components/AlertModal/AlertModal";
import SnackBar from "src/components/SnackBar/SnackBar";
import { getLookUpDataService } from "src/_services/lookUp";
import ImageRotate from "../Profile/Rotate";
// import DescriptionIcon from '@mui/icons-material/Description';
import ReactSpinner from "src/components/ReactSpinner/ReactSpinner";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { InfoOutlined } from "@mui/icons-material";
import ToolTip from "src/components/Tooltip/tooltip";
const PREFIX = "Documents";

const classes = {
  uploadAlign: `${PREFIX}-uploadAlign`,
  reactSpinner: `${PREFIX}-reactSpinner`,
  addDocument: `${PREFIX}-addDocument`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.uploadAlign}`]: {
    margin: "1rem 1rem 0 1rem",
  },

  [`& .${classes.reactSpinner}`]: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  [`& .${classes.addDocument}`]: {
    fontSize: "13px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "11px",
      padding: "4px",
    },
  },
}));

const Documents = (props) => {
  const [documentsData, setDocumentsData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [documentID, setDocumentID] = useState();
  const [spinner, setSpinner] = useState(false);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState(false);

  const getDocuments = () => {
    setSpinner(true);
    userDocuments.getDocuments(props.data).then((res) => {
      if (res) {
        if (res.status === 200) {
          setDocumentsData(res.data);
          setSpinner(false);
        }
      } else {
        setSpinner(false);
      }
    });
  };

  const handleDownload = async (item) => {
    try {
      await getLookUpDataService.getObjectUrl(item.fileid).then(async (res) => {
        if (res.data.success) {
          const fileUrl = res.data.data;

          const options = {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          };

          const response = await fetch(fileUrl, options);

          if (response.status === 200) {
            const file = await response.blob();
            const filename = response.headers["Content-Disposition"].split(
              "filename="
            )[1];
            await saveFile(file, filename);
          }
        }
      });
    } catch {
      setOpenSnackBar(true);
      setSeverity("error");
      setSnackbarTitle("Download failed");
    }
  };

  const saveFile = async (file, filename) => {
    await new Promise((resolve, reject) => {
      let blobURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = blobURL;
      link.setAttribute("download", filename);
      link.click();
      blobURL = null;
      resolve();
    });
  };

  useEffect(() => {
    if (props.data !== null) {
      //get documents from server
      getDocuments();
    }
  }, [props.data]);

  const closeModalHandler = () => {
    setOpenModal(false);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleFileDialog = (item, name,doc) => {
    setShow(true);
    getLookUpDataService.getObjectUrl(item).then((res) => {
      if (res.data.success) {
        setUrl({
          url: res.data.data,
          title: name,
          isPdf:
            res.data.mimetype === "application/pdf" ||
            res.data.mimetype === ".pdf"
              ? true
              : false,
          doc:doc
        });
      } else {
        setShow(false);
      }
    });
  };

  //create form submit
  const submitHandle = (values) => {
    const formData = new FormData();
    formData.append("document_type", values.documentType);
    formData.append("document_name", values.others);
    formData.append("file", values.files);
    formData.append("notes", values.notes);
    userDocuments.addDocument(props.data, formData).then((res) => {
      if (res) {
        if (res.status === 201) {
          setOpenModal(false);
          getDocuments();
          setSeverity("success");
          setSnackbarTitle("Document Uploaded ");
          setOpenSnackBar(true);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
            setOpenModal(false);
          }
        }
      }
    });
  };

  const deleteDocument = () => {
    setLoadSpinner(true);
    setDeleteConfirm(false);
    setLoadSpinner(false);
    userDocuments.deleteDocument(props.data, documentID).then((res) => {
      if (res) {
        if (res.status === 200) {
          getDocuments();
          setSeverity("success");
          setSnackbarTitle("Document Deleted");
          setOpenSnackBar(true);
        } else {
          if (res.status === 401 && res.data.name === "TokenExpiredError") {
            setOpenSessionExpiredModal(true);
          }
        }
      }
    });
  };

  const handleConfirm = (docID) => {
    setDeleteConfirm(true);
    setDocumentID(docID);
  };

//   const downloadDocument = (url) => {
//     axios
//       .get(url, {
//         method: "GET",
//         headers: {
//           ContentType: "application/pdf",
//         },
//       })
//       .then((resp) => resp.blob())
//       .then((blob) => {
//         const url = window.URL.createObjectURL(blob);
//         const a = document.createElement("a");
//         a.style.display = "none";
//         a.href = url;
//         // the filename you want
//         a.download = "todo-1.json";
//         document.body.appendChild(a);
//         a.click();
//         window.URL.revokeObjectURL(url);
//         alert("your file has downloaded!"); // or you know, something with better UX...
//       })
//       .catch(() => alert("oh no!"));
//   };

//   const downloadEmployeeData = (file_url) => {
//     fetch(file_url).then((response) => {
//       response.blob().then((blob) => {
//         let url = window.URL.createObjectURL(blob);
//         let a = document.createElement("a");
//         a.href = url;
//         a.download = "employees.json";
//         a.click();
//       });
//       //window.location.href = response.url;
//     });
//   };

  return (
    <Root>
      <NormalCard
        title=" Documents"
        btn={ (
            <Button
              color="primary"
              disabled={props.data !== null ? false : true}
              className={classes.addDocument}
              onClick={() => setOpenModal(true)}
              size="medium"
              type="submit"
              variant="outlined"
            >
              Add Document
            </Button>
          ) 
        }
      >
        <div className={classes.uploadAlign}>
          <Grid item md={12} sm={12}>
            <List dense={true}>
              {spinner && (
                <div className={classes.reactSpinner}>
                  <ReactSpinner />
                </div>
              )}
              {documentsData &&
                documentsData.map((item) => (
                  <React.Fragment key={item.file_url}>
                    <ListItem>
                      <ListItemIcon>
                        {(item.mime_type === "image/jpg" ||
                          item.mime_type === "image/gif" ||
                          item.mime_type === "image/png" ||
                          item.mime_type === "image/jpeg") && <ImageIcon />}
                        {item.mime_type === "application/pdf" && (
                          <PictureAsPdfIcon />
                        )}
                        {/* {item.mime_type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (<DescriptionIcon />)} */}
                      </ListItemIcon>
                      <ListItemText
                        button
                        primary={
                          item.code === "OTHERS" ? (
                            <Typography
                              style={{ maxWidth: 300, cursor: "pointer",display:'flex' ,alignItems:'center'}}
                              // component={"a"}
                              onClick={() =>
                                handleFileDialog(
                                  item.fileid,
                                  item.document_name
                                )
                              }
                              noWrap={false}
                              // href={`${item.file_url}`}
                              color="primary"
                              target="_blank"
                            >
                              <span>
                              {item.document_name}
                              </span>
                             {item.notes ? <ToolTip title={item.notes} placement='right'>
                              <InfoOutlined sx={{fontSize:'14px',ml:1}}/>
                              </ToolTip> :null}
                            </Typography>
                          ) : (
                            <Typography
                              style={{ maxWidth: 300, cursor: "pointer" }}
                              // component={"a"}
                              noWrap={false}
                              onClick={() =>
                              {
                                handleFileDialog(item.fileid, item.description,item.code)
                              }
                              }
                              // href={`${item.file_url}`}
                              color="primary"
                              // Download
                              target="_blank"
                            >
                              {item.description}
                            </Typography>
                          )
                        }
                        // secondary={secondary ? 'Secondary text' : null}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="download"

                          // onClick={() => downloadEmployeeData(item.file_url)}
                        >
                          {" "}
                          <Typography
                            style={{ maxWidth: 300 }}
                            component={"a"}
                            noWrap={false}
                            // href={`${item.file_url}`}
                            color="primary"
                            target="_blank"
                            onClick={() => handleDownload(item)}
                            // download={`${item.description}.pdf`}
                          >
                            <CloudDownloadIcon />
                          </Typography>
                        </IconButton>
                        {/* {props.edit ? ( */}
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleConfirm(item.id)}
                          >
                            <Typography
                              style={{ maxWidth: 300 }}
                              color="primary"
                            >
                              <DeleteIcon />
                            </Typography>
                          </IconButton>
                        {/* ) : null} */}
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                ))}
            </List>
          </Grid>
        </div>
      </NormalCard>
      <ModalCentered
        title={"Add Document"}
        open={openModal}
        toggle={closeModalHandler}
        children={<DocumentCreateForm formSubmit={submitHandle} />}
      />
      <AlertModal
        title="Are you sure you want to delete this document?"
        open={deleteConfirm}
        toggle={() => setDeleteConfirm(false)}
        onConfirm={deleteDocument}
        openLoading={loadSpinner}
      ></AlertModal>

      <SessionExpiredModal open={openSessionExpiredModal} />
      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
      <ModalCentered
        title={url.title ? url.title : "Loading..."}
        fullScreen={true}
        open={show}
        toggle={() => {
          setShow(false);
          setUrl(false);
        }}
        children={
          url.url ? (
            <div style={{ textAlign: "center", position: "relative" }}>
              <Grid
                container
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Grid item xs={12} md={10}>
                 {url.doc ==="PAN"  && props.pan ?
                    <Typography textAlign={'center'} m={2}><Typography component={'span'} sx={{fontWeight:'bold'}}>PAN : </Typography> {props.pan} </Typography> : null}

                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {url.isPdf ? (
                      <iframe
                      title="iframe"
                        style={{
                          width: "100%",
                          height: "calc(100vh - 100px)",
                        }}
                        src={url.url}
                      />
                    ) : (
                      <ImageRotate
                        alt="cool image"
                        type="users"
                        image={url && url.url}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
              <br />
            </div>
          ) : (
            <div>Loading...</div>
          )
        }
      />
    </Root>
  );
};

Documents.propTypes = {
  data: PropTypes.number,
};

Documents.defaultProps = {
  data: null,
};

export default Documents;
