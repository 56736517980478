import React, { useEffect, useState } from 'react'
import MUIDataTable from "mui-datatables";
import { Typography, Button, Link } from '@mui/material'
import {  ThemeProvider } from '@mui/material/styles';
import Page from 'src/components/Page';
import NormalCard from 'src/components/Cards/NormalCard'
import { useNavigate, NavLink } from 'react-router-dom'
import ModalCentered from "src/components/Modals/ModalCentered";
import SnackBar from "src/components/SnackBar/SnackBar";
import CreateLotForm from "../ChannelPartnersView/CreateLotForm"
import { connect } from 'react-redux';
import { ProcurementService } from "src/_services/procurement"
import Details from "./Details"
import DetailsModal from "src/components/Modals/ServiceOrderStatusModal"
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal"
import GetMuiTheme from 'src/components/theme';

const LinkBtn = React.forwardRef(function LinkBtn(props, ref) { // eslint-disable-line
    return <NavLink to={props.to} {...props}  />; // eslint-disable-line
});



// const useStyles = makeStyles(theme => ({
//     tableEllips: {
//         overflow: "hidden",
//         textOverflow: "ellipsis",
//         width: '7rem',
//         [theme.breakpoints.down('sm')]: {
//             width: '8rem',
//         },
//         [theme.breakpoints.up('sm')]: {
//             width: '7rem',
//         },
//     },
//     root: {
//         margin: theme.spacing(2),
//         '& .MuiTableHead-root': {
//             background: 'blue'
//         }
//     },
//     btnright: {
//         textAlign: 'end'
//     },
//     container: {
//         minHeight: '100%',
//         padding: theme.spacing(3)

//     },
//     editdeletbtn: {
//         color: '#3363cb',
//         display: 'flex'
//     },
//     button: {
//         backgroundColor: "#324C90",
//         color: "white",
//         cursor: 'pointer',
//         outlineColor: "#324C90",
//         padding: 8,
//         border: "none"
//     },
//     btnsdltedt: {
//         marginRight: theme.spacing(1)
//     },
//     chipMargin: {
//         marginRight: "5px",
//         marginBottom: "3px",
//         fontSize: "12px !important",
//         padding: 0,
//         height: "20px",
//         paddingLeft: "0px !important",
//         paddingRight: "0px !important"
//     },
//     iconbtn: {
//         padding: theme.spacing(0.8)
//     }
// }))

const InvestorClintProcurement = (props) => {


    const navigate = useNavigate();

    const [userData, setUserData] = useState([])
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [titleDetailsModal, setTitleDetailsModal] = useState("");
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackbarTitle, setSnackbarTitle] = useState("");
    const [titleModal, setTitleModal] = useState("");
    const [severity, setSeverity] = React.useState("");

    const [details, setDetails] = useState({})

    const [securityId, setSecurityId] = useState([])
    const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(false)


    

    const handleModalClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenModal(false);
    };

    const handleDetailsModalClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenDetailsModal(false);
    };

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackBarOpen(false);
    };

    const handleOpen = (id) => {
        setSecurityId(id)
        setTitleModal("Requested Quantity")
        setOpenModal(true)
    }


    const handleDetailsModalOpen = (id, status, orderPlacedDate, remarks, requestedQuantity) => {

        var details = {}
        details.id = id
        details.status = status
        details.remarks = remarks
        details.orderPlacedDate = orderPlacedDate
        details.requestedQuantity = requestedQuantity

        setDetails(details)
        setTitleDetailsModal("Procure Order Details")
        setOpenDetailsModal(true)

    }


    const handleProcureAgain = (id) => {
        setOpenDetailsModal(false)
        handleOpen(id);
    }

    const getInventoryPrice = () => {
        setLoading(true)
        ProcurementService.getProcurement()
            .then(res => {
                if (res) {
                    

                    if (res.status === 200) {
                        
                        if (res.data === "") {
                            setUserData([])
                            setLoading(false)
                        }
                        else if (res.data.length > 0) {
                            setUserData(res.data.filter((data) => {
                                return (data.price !== "0" || null) && (data.quantity !== "0" || null)
                            }))
                            setLoading(false)
                        }
                        else {
                            setUserData([])
                            setLoading(false)
                        }
                    }else{
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setLoading(false);
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })

    }






    useEffect(() => {
        getInventoryPrice()
    }, [])







    const handleLotSubmit = (values) => {



        ProcurementService.postProcurement(values)
            .then((res) => {
                if (res) {
                    

                    if (res.status === 201) {
                        getInventoryPrice()
                        setSeverity("success")
                        setSnackbarTitle("The order is placed")
                        setSnackBarOpen(true)
                        setOpenModal(false)
                    } else if (res.status === 400) {
                        setSeverity("error")
                        setSnackbarTitle(`Please update  PAN, DEMAT ACCOUNT, BANK ACCOUNT to Procure Shares.`)
                        setTimeout(
                            () => navigate(`/app/profile`, { replace: true }),
                            3000
                        );
                        setSnackBarOpen(true)
                        setOpenModal(false)
                    }else{
                        if (res.status === 401 && res.data.name === "TokenExpiredError") {
                            setOpenModal(false);
                            setOpenSessionExpiredModal(true)
                        }
                    }
                }
            })

    }



    const getMuiTheme = GetMuiTheme



    const columns = [
        {
            name: "security_id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false
            }
        },


        {
            name: "is_procurement_placed",
            label: "is_procurement_placed",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false
            }
        },
        {
            name: "lot",
            label: "lot",
            options: {
                filter: false,
                sort: false,
                hide: true,
                display: false
            }
        },

        {
            name: "name",
            label: "Scrip Name",
            options: {
                hint: 'Name of the scrip',
                filter: false,
                sort: false,
                // customBodyRender: (value) => {
                //     return (
                //         <Typography>
                //             {value}
                //         </Typography>)
                // }
            }
        },
        {
            name: "parent_company_name",
            label: "Company",
            options: {
                hint: ' Scrip Associated Company ',
                filter: false,
                sort: false,

            }
        },
        {
            name: "isin",
            label: "ISIN",
            options: {
                hint: 'International scrip Identification  Number - Unique code for scrip ',
                filter: false,
                sort: false,
            }
        },
        {
            name: "sector",
            label: "Sector",
            options: {
                hint: 'Industry Sectors',
                filter: false,
                sort: false,
            }
        },


        {
            name: "price",
            label: "Price",
            options: {
                hint:"Potential Share Price",
                filter: false,
                sort: false,
            }
        },
        {
            name: "inventory_quantity",
            label: "Quantity",
            options: {
                hint:"Number of Stocks",
                filter: false,
                sort: false,
            }
        },



        {
            name: "latest_procurement_datetime",
            label: "Order Placed On",
            options: {
                filter: false,
                sort: false,
                display: false,

            }
        },
        {
            name: "remark",
            label: "Remark",
            options: {
                hint: "Potential Share Price",
                filter: false,
                sort: false,
                hide: true,
                display: false
            }
        },

        {
            name: "action",
            label: "Status",
            options: {
                hint:"Status",
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta) => {
                    return (
                        <div style={{ display: "flex" }}>
                            <Button
                                disabled={tableMeta.rowData[1] === true}
                                size="small"
                                style={{ width: 130 }}
                                variant="outlined"
                                color="primary"
                                onClick={() => handleOpen(tableMeta.rowData[0])}
                            >
                                {tableMeta.rowData[1] === true ? <>{tableMeta.rowData[2] === "PLACED" ? `${tableMeta.rowData[2]}` : `${tableMeta.rowData[2]}ED`}</> : "PROCURE"}
                            </Button>
                            {tableMeta.rowData[1] === true ?
                                <Typography variant="subtitle2" style={{ marginLeft: 16, marginTop: 5, fontWeight: 400, cursor: "pointer" }}><Link onClick={() => { handleDetailsModalOpen(tableMeta.rowData[0], tableMeta.rowData[2], tableMeta.rowData[10], tableMeta.rowData[11], tableMeta.rowData[3]) }}>Details</Link></Typography>
                                : <></>}
                        </div>
                    )
                }
            }
        }
    ];


    return (
        <Page title="Unlistedkart | Users List">
            <NormalCard title={"Buy Details"} 
            // subtitle={"List of Scrips Available to raise Procure Request"}
            >
                <ThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={userData}
                        columns={columns}
                        options={{
                            download: true,
                            print: false,
                            filter: false,
                            viewColumns: false,
                            rowsPerPage: 10,
                            rowsPerPageOptions: [5, 10, 25],
                            // responsive: 'scrollMaxHeight',
                            selectableRows: "none",
                            sortOrder: {
                                name: 'latest_procurement_datetime',
                                direction: 'desc'
                            },
                            textLabels: {
                                body: {
                                    noMatch: loading ?
                                        'Loading...' : 'Sorry, there are no matching data to display'
                                },
                            },
                        }}
                    />
                </ThemeProvider>
            </NormalCard>

            {/* <ModalCentered
                title={titleModal}

                open={openModal}
                toggle={handleModalClose}
                children={<CreateLotForm formSubmit={handleLotSubmit} security_id = {securityId} />}
            /> */}

            <ModalCentered
                title={titleModal}

                open={openModal}
                toggle={handleModalClose}
                children={<CreateLotForm formSubmit={handleLotSubmit} security_id={securityId} />}
            />

            <DetailsModal
                title={titleDetailsModal}
                open={openDetailsModal}
                toggle={handleDetailsModalClose}
                children={<Details details={details} callBack={handleProcureAgain} />}
            />

            <SnackBar
                open={snackBarOpen}
                severity={severity}
                close={handleClose}
                snackbarTitle={snackbarTitle}
            />
            <SessionExpiredModal
                open={openSessionExpiredModal}
            />
        </Page>
    )
}

const mapStateToProps = state => {
    return ({
        force: state, // force state from reducer
        authReducer: state.authReducer,
    })
};
export default connect(mapStateToProps, null)(InvestorClintProcurement);



