import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { getLookUpDataService } from "src/_services/lookUp";
import Autocomplete from "@mui/material/Autocomplete";
const PREFIX = "CreateSalesGroup";
const classes = {
  container: `${PREFIX}-container`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    // justifyItems: "center",
    marginTop: "10%",
  },
  [`& .${classes.popDownBtn}`]: {
    // borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
    textTransform: "none",
  },
  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

const CreateSalesGroup = (props) => {
  const [loading, setLoading] = React.useState(false);
  const [internalRM, setInternalRM] = React.useState([]);
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );

  useEffect(() => {
    getLookUpDataService
      .getLookUpUser("SALES,OPERATION")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_pan ?items.user_pan?.toUpperCase() :items.user_code.toUpperCase()} )`,
              };
            });
            setInitValues({
                teamLead:item.filter((item)=>item.id === props?.data?.teamLead)[0],
                teamMembers:item.filter((item)=>props?.data?.teamMembers.includes(item.id)),
                id:props?.data?.id
            })
            setInternalRM(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log("error");
      });
     
  }, []);




  const [initValues,setInitValues] = useState({
    teamLead:'',
    teamMembers:[],
    id:''
  });

  const selectOption = (
    value,
    options,
    setFieldValue,
    name,
    label,
    touched,
    errors,
    values
  ) => {
    return (
      <Grid item xs={12}>
        <Autocomplete
        //   id={name}
          name="investorOrVendor"
          multiple ={name ==='teamMembers'}
          value={value}
          filterSelectedOptions
          options={options}
          getOptionLabel={(option) => option.lable || ""}
          onChange={async (e, value) => {
            if (value !== null) {
              setFieldValue(name, value);
              if(name ==='teamLead'){
                setFieldValue('teamMembers',[...values.teamMembers.filter((item)=>item.id !== value.id)]);
              }
            }else{
              setFieldValue(name, "");
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              helperText={touched && errors}
              error={Boolean(touched && errors)}
            />
          )}
        />
        <ErrorFocus />
      </Grid>
    );
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          teamLead: Yup.string().required("Required"),
          // teamMembers:Yup.array().min(1,"Required")
        })}
        onSubmit={(values) => {
          setLoading(true);
          const data = {
            teamMembers:  values.teamMembers.map((item)=>item.id),
            teamLead: values.teamLead.id,
            id:values.id
          };
          props.formSubmit(data);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                {selectOption(
                    values.teamLead,
                    internalRM,
                    setFieldValue,
                    "teamLead",
                    `Team Lead*`,
                    touched.teamLead,
                    errors.teamLead,
                    values
                  )}
                  </Grid>
               <Grid item xs={12} sm={12} md={12} lg={12}>
                  {selectOption(
                    values.teamMembers,
                    values.teamLead ? internalRM.filter((item)=>item.id !== values.teamLead.id) : internalRM,
                    setFieldValue,
                    "teamMembers",
                    "Team Members",
                    touched.teamMembers,
                    errors.teamMembers,
                    values
                  )}
                </Grid>
               
               
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", gap: 2, justifyContent: "end" }}
                >
                  <Button
                    color="primary"
                    className={classes.popDownBtn}
                    size="medium"
                    type="submit"
                    name="submitType"
                    value="report"
                    variant="contained"
                  >
                    {loading === false ? (
                      <> {`${props.mode ==='edit' ? 'Update' :'Create'} Group`}</>
                    ) : (
                      <>
                        <img
                          alt="loader"
                          src={loadingSpinner}
                          className={classes.buttonProgress}
                        />{" "}
                        {props.mode ==='edit' ? 'Update' :'Create'} Group
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </form>
        )}
      </Formik>
      <SessionExpiredModal open={openSessionExpiredModal} />
    </StyledContainer>
  );
};

export default CreateSalesGroup;
